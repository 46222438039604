import appConfig, { DEV_ENDPOINT } from 'app/app-config';
import Airbrake from 'app/airbrake/airbrake-client';

export interface ParamData {
    roomName: string;
    userName?: string;
    accountId?: string;
    autoJoin?: string | number;
    kmsServer?: string;
    port?: string;
    beta?: string;
    alpha?: string;
    useTvxBeta?: string;
    useTvxUrl?: string;
}

const ROOM_PARAM_DESCRIPTOR = 'room=';
const NAME_PARAM_DESCRIPTOR = 'name=';
const ACCOUNT_ID_PARAM_DESCRIPTOR = 'ai=';
const AUTO_JOIN_PARAM = 'j=';
// Legacy load balancing - what # of Kurento media server
const KMS_PARAM = 'k=';
const PORT_PARAM = 'p=';
const BETA_PARAM = 'beta=';
const ALPHA_PARAM = 'alpha=';
const USE_TVX_BETA = 'useTvxBeta=';
const USE_TVX_URL = 't=';

const DEFAULT_HOST = '1';
const DEFAULT_PORT = '443';

export const parseGetParams = () => {
    const params = window.location.search
        .substring(1)
        .split(/&/g);

    const urlParams: ParamData = {
        roomName: getParamValue(params, ROOM_PARAM_DESCRIPTOR),
        userName: getParamValue(params, NAME_PARAM_DESCRIPTOR),
        accountId: getParamValue(params, ACCOUNT_ID_PARAM_DESCRIPTOR),
        autoJoin: getParamValue(params, AUTO_JOIN_PARAM),
        kmsServer: getParamValue(params, KMS_PARAM),
        port: getParamValue(params, PORT_PARAM),
        beta: getParamValue(params, BETA_PARAM),
        alpha: getParamValue(params, ALPHA_PARAM),
        useTvxBeta: getParamValue(params, USE_TVX_BETA),
        useTvxUrl: getParamValue(params, USE_TVX_URL),
    };
    return urlParams;
};

/**
 * Takes a array, params, and a given search string, searchString,
 * and finds the value, stripping the seachstring and decoding the
 * URI, or returning an empty string
 */
const getParamValue = (params: Array<string>, searchString: string) => {
    const length: number = searchString.length;
    const regExp: RegExp = new RegExp(`^${searchString}`);
    const value = params.find(object => regExp.test(object)) || '';
    return decodeURI(value.substring(length));
};

export const createHostUrl = (host: string | undefined, port: string | undefined): string => {
    if (!appConfig.production) {
        return DEV_ENDPOINT;
    }
    if (!host) {
        Airbrake.notify({
            error: `Host param missing when creating host url. Received: '${host}'. Defaulting to '${DEFAULT_HOST}'.`,
            context: { severity: "warning" }
        })
    }
    host = host ? host : DEFAULT_HOST;
    port = port ? port : DEFAULT_PORT;
    const portString: string = port === "443" ? "" : `:${port}`;

    return `wss://kms${host}.telavox.se${portString}/groupcall`;
};
