
interface SegmentEvents {
    ENTERED_LOBBY: string;
    JOINED_ROOM: string;
    LEFT_ROOM: string;
    ACTIVATED_CAMERA: string
    SHARED_SCREEN: string;
};

export const EVENT_STRINGS: Readonly<SegmentEvents> = Object.freeze({
    ENTERED_LOBBY: 'User entered lobby',
    JOINED_ROOM: 'User joined room',
    LEFT_ROOM: 'User left room',
    ACTIVATED_CAMERA: 'User activated camera',
    SHARED_SCREEN: 'User shared screed'
});
