import * as React from "react";

interface InputProps {
    beta?: string;
}

interface ComponentState {
    infoOpen:  boolean;
}

export class BetaInfo extends React.PureComponent<InputProps, ComponentState> {
    constructor(props: InputProps) {
        super(props);

        this.state = {
            infoOpen: false,
        };
    }

    public render() {
        const wrapperStyle: React.CSSProperties = {
            display: this.props.beta ? 'block' : 'none',
            right: 0,
            padding: 12,
            zIndex: 999
        };
        const infoTextStyle: React.CSSProperties = {
            display: this.state.infoOpen ? 'block' : 'none',
            background: '#4d4d4d',
            position: 'absolute',
            right: 36,
            padding: 12,
            width: 250,
            fontSize: 12
        };

        return (
            <div style={wrapperStyle}>
                <div style={{ cursor: 'pointer' }} onClick={() => {
                    this.setState({ infoOpen: !this.state.infoOpen });
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24px" height="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg>
                </div>
                <div style={infoTextStyle}>
                    <p>You have activated beta mode for this Video Conference.</p>
                    <p>Please report any issues <a href={'https://forms.gle/EyaFqJN3njBBCQEv6'} target={'_blank'} style={{ color: '#BBBBBB' }}>here</a>.</p>
                    <p>With the beta, you're testing: Rooms up to 10 participants</p>

                </div >
            </div >
        )
    }
}
