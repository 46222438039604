import * as Immutable from "immutable";
import * as Redux from "redux";
import { ThunkDispatch } from "redux-thunk";

import * as Client from "state/client";

export type ActiveSource = "camera" | "screen" | "window" | "application" | "desktop";
export type PermissionState = 'granted' | 'prompt' | 'denied';

export interface State {
    chromeExtensionExists: boolean;
    chromeMediaSourceId:   string;

    mediaSourceSupport:    boolean;
    activeSource:          ActiveSource;

    micPermission:         PermissionState;
    camPermission:         PermissionState;

    alpha: boolean;
}

const StateRecord = Immutable.Record({
    chromeExtensionExists: false,
    chromeMediaSourceId: null,
    activeSource: "camera",
    mediaSourceSupport: !!(navigator.mediaDevices.getSupportedConstraints() as any).mediaSource,
    micPermission: "prompt",
    camPermission: "prompt",
    alpha: false,
});

const initialState = new StateRecord();

export const reducer = (state = initialState, action: Redux.Action) => {
    switch (action.type) {
        case "chromeExtensionAvailable": {
            return state.set("chromeExtensionExists", true);
        }

        case "setSourceId": {
            return state.set("chromeMediaSourceId", (action as any).sourceId);
        }

        case "setActiveSource": {
            return state.set("activeSource", (action as any).source);
        }

        case "setMicPermission": {
            return state.set("micPermission", (action as any).permission);
        }

        case "setCamPermission": {
            return state.set("camPermission", (action as any).permission);
        }

        case "enableAlpha": {
            return state.set("alpha", true);
        }

        default: {
            return state;
        }
    }
};

const setupMessageListener = (dispatch: ThunkDispatch<any, {}, any>) =>
    window.addEventListener("message", event => {
        if (event.data === "flow-extension-loaded") {
            console.info("Flow Extension loaded.");

            dispatch({
                type: "chromeExtensionAvailable"
            });
        } else if (event.data && event.data.sourceId) {
            const {data: {sourceId}} = event;

            dispatch({
                type: "setSourceId",
                sourceId: sourceId
            });

            dispatch(Client.Actions.activateScreenSharing("desktop"));
        } else if (event.data === "flow-error-permission-denied") {
            dispatch(Actions.activateSource("camera"));
        }
    });

export const Actions = {
    activateChromeExtension: () => (dispatch: Redux.Dispatch<any>) => {
        setupMessageListener(dispatch);

        window.postMessage("flow-are-you-there", "*");
    },

    selectSourceId: () => (dispatch: ThunkDispatch<any, {}, any>) => {
        dispatch(() => window.postMessage("flow-get-sourceId", "*"));
    },

    activateSource: (source: ActiveSource) => {
        return {
            type: "setActiveSource",
            source: source
        };
    },

    setMicPermission: (permission: PermissionState) => ({
        type: "setMicPermission",
        permission
    }),

    setCamPermission: (permission: PermissionState) => ({
        type: "setCamPermission",
        permission
    }),

    enableAlpha: () => ({
        type: "enableAlpha",
    })
};
