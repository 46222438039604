import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from 'redux-thunk';

import { RootState } from "state/store";
import * as Client from "state/client";
import {
    ActiveSource,
    PermissionState
} from 'state/app';
import {CSSProperties, RefObject} from 'react';
import * as JsSIP from "jssip";
import {GroupCallClient} from "../../groupcall-client";
import { DEFAULT_SIP_PROXIES } from 'app/utils/conference-utils';

interface FromStateProps {
    ownName: string;
    activeSource: ActiveSource;
    micPermission: PermissionState;
    camPermission: PermissionState;
    alpha: boolean;
    meetingCode: string;
    client: GroupCallClient;
}

interface InputProps {
    hideLocalSession: boolean;
    ownVideoMinimized: boolean;
    fullScreen: boolean;
}
interface ComponentState {}

const AudioMuteIcon: React.SFC<{}> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
            <path fill='white' d="M19 11h-1.7c0 .74-.16 1.43-.43 2.05l1.23 1.23c.56-.98.9-2.09.9-3.28zm-4.02.17c0-.06.02-.11.02-.17V5c0-1.66-1.34-3-3-3S9 3.34 9 5v.18l5.98 5.99zM4.27 3L3 4.27l6.01 6.01V11c0 1.66 1.33 3 2.99 3 .22 0 .44-.03.65-.08l1.66 1.66c-.71.33-1.5.52-2.31.52-2.76 0-5.3-2.1-5.3-5.1H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c.91-.13 1.77-.45 2.54-.9L19.73 21 21 19.73 4.27 3z" />
        </svg>

    );
};
interface FromDispatchProps {
    setupSenderPeer:    (name: string, video: HTMLVideoElement) => void;
    setupScreenSenderPeer:    (name: string, video: HTMLVideoElement) => void;
    registerSipSession: (session: JsSIP.RTCSession) => void;
    terminateSipSession: () => void;
}

type Props = FromStateProps & FromDispatchProps & InputProps;

class LocalSessionComponent extends React.PureComponent<Props, ComponentState> {

    private localVideoRef?: HTMLVideoElement;
    private remoteAudio: RefObject<HTMLAudioElement>;

    constructor(props: Props) {
        super(props);

        this.remoteAudio = React.createRef();
    }

    public componentDidMount() {
        if (this.props.activeSource === "camera") {
            this.props.setupSenderPeer(this.props.ownName, this.localVideoRef!);
        } else {
            this.props.setupScreenSenderPeer(this.props.ownName, this.localVideoRef!);
        }

        const sipProxySockets = DEFAULT_SIP_PROXIES.map(url => {
            return {
                socket: new JsSIP.WebSocketInterface(url), // TODO: Hardcoded, not good, call the endpoint for sockets instead
                weight: 30
            }
        });
        if (this.props.alpha) {
            const configuration = {
                connection_recovery_max_interval: 120,
                password: 'NoJKasJf1D',
                register: false, // We will never accept incoming calls
                session_timers: false,
                sockets: sipProxySockets,
                uri: "sip:u013244880@sip.telavox.se",
                user_agent: "external-conference-dev"
            };

            const trimOutgoingSdp = (data: any) => {
                if (data.originator !== 'local') {
                    return;
                }

                if (data.type !== 'offer') {
                    return;
                }
            };

            const handleRemoteStream = (e:any) => {
                const remoteAudio = this.remoteAudio.current;
                // @ts-ignore
                if (remoteAudio) {
                    remoteAudio.srcObject = e.stream;
                }
            };

            // @ts-ignore
            const userAgent = new JsSIP.UA(configuration);
            userAgent.on('newRTCSession', () => console.log('Call: newRTCSession'));
            userAgent.on('connected', () => console.log('Call: connected'));
            userAgent.on('disconnected', () => console.log('Call: disconnected'));
            userAgent.on('registered', () => console.log('Call: registered'));
            userAgent.on('unregistered', () => console.log('Call: unregistered'));
            userAgent.on('registrationExpiring', () => console.log('Call: registrationExpiring'));
            userAgent.on('registrationFailed', () => console.log('Call: registrationFailed'));
            userAgent.start();

            let callAttempt = 0;
            const createCallSession = this.createCallSession;
            const sendAudioDisabled = () => this.props.client.sendTrackDisabled('audio');
            const sendAudioEnabled = () => this.props.client.sendTrackEnabled('audio');

            const eventHandlers = {
                'progress': function(data: any) {
                    if (data.originator === 'remote') {
                        data.response.body = null;
                    }
                    console.log('Call: call is in progress', data);
                },

                'accepted': function(e: any) {
                    console.log('Call: accepted, : ', e);
                },

                'failed': function(e: any) {
                    console.log('Call: call failed with cause: ', e);
                    if (callAttempt < 20) {
                        callAttempt++;
                        // Try again
                        console.log('Call: Trying again');
                        createCallSession(userAgent, trimOutgoingSdp, options, handleRemoteStream);
                    } else {
                        sendAudioDisabled();
                        console.error('Call: Max number of reconnects');
                        // TODO: WEB-5790 Send error that audio is off
                    }
                },

                'ended': function(e: any) {
                    console.log('Call: call ended with cause: ', e);
                },

                'hold': function(e: any) {
                    console.log('Call: call on hold, ', e);
                },

                'unhold': function(e: any) {
                    console.log('Call: call on unhold, ', e);
                },

                'confirmed': function(e: any) {
                    sendAudioEnabled();
                    console.log('Call: call confirmed', e);
                }
            };

            // See: https://stackoverflow.com/a/30106551/9191450
            const b64EncodeUnicode =  (str: string) => {
                return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
                    // @ts-ignore
                    function toSolidBytes(match, p1) {
                        // @ts-ignore
                        return String.fromCharCode('0x' + p1);
                    }));
            };

            const meetingCode = this.props.meetingCode;
            const options = {
                'eventHandlers'    : eventHandlers,
                'mediaConstraints' : { 'audio': true, 'video': false },
                extraHeaders: [
                    `X-TVX-CONFERENCE-MEETING-CODE: ${meetingCode}`,
                    `X-TVX-CONFERENCE-DISPLAY-NAME: ${b64EncodeUnicode(this.props.ownName)}`
                ]
            };

            this.createCallSession(userAgent, trimOutgoingSdp, options, handleRemoteStream);

            const beforeUnloadHandler = () => {
                this.props.terminateSipSession();
            };
            window.addEventListener('beforeunload', beforeUnloadHandler);
        }
    }

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.activeSource !== this.props.activeSource) {
            if (this.props.activeSource === "camera") {
                this.props.setupSenderPeer(this.props.ownName, this.localVideoRef!);
            } else {
                this.props.setupScreenSenderPeer(this.props.ownName, this.localVideoRef!);
            }
        }
    }

    private createCallSession = (userAgent: any, trimOutgoingSdp: Function, options: any, handleRemoteStream: Function) => {
        const session = userAgent.call('sip:+46770339988@sip.telavox.se', options);
        session.on('sdp', trimOutgoingSdp);
        session.connection.onaddstream = handleRemoteStream;
        this.props.registerSipSession(session);
    };

    private renderCameraNotAvailable = () => {
        return (
            <div className='cam-unavailable'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffffff" width="24px" height="24px"><path d="M0 0h24v24H0zm0 0h24v24H0z" fill="none"/><path d="M21 6.5l-4 4V7c0-.55-.45-1-1-1H9.82L21 17.18V6.5zM3.27 2L2 3.27 4.73 6H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.21 0 .39-.08.54-.18L19.73 21 21 19.73 3.27 2z"/></svg>
            </div>
        );
    };

    private MaximizeMinimizeFullscreen = () => {
        return (
            <div className='expand-button-wrapper'>
                <div className='expand-button'>
                    {this.props.ownVideoMinimized ? (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffffff" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 11h-8v6h8v-6zm4 8V4.98C23 3.88 22.1 3 21 3H3c-1.1 0-2 .88-2 1.98V19c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2zm-2 .02H3V4.97h18v14.05z"/></svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffffff" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M6 19h12v2H6z"/></svg>
                    )}
                </div>
            </div>
        );
    };

    private renderMicNotAvailable = () => {
        const { camPermission, ownVideoMinimized } = this.props;
        let className = '';
        if (camPermission !== 'denied') {
            className = `mic-unavailable ${ownVideoMinimized ? 'mic-minimized' : undefined}`;
        }

        return (
            <div className={className}>
                <AudioMuteIcon />
            </div>
        );
    };

    private renderVideoTag = (flipLocal: boolean) => {
        return (
            <video
                key="local"
                ref={(r: HTMLVideoElement) => this.localVideoRef = r}
                autoPlay={true}
                className={`${flipLocal ? 'flipped' : ''}`}
                style={this.props.ownVideoMinimized ? { height: 48 } : {}}
            />
        );
    };

    public render() {
        const { micPermission, camPermission, activeSource, hideLocalSession } = this.props;
        const flipLocal = activeSource === "camera";
        const localWrapperDisplayMode = hideLocalSession ? 'none' : 'flex';
        const micNotAvailable = !micPermission || micPermission === "denied";
        const cameraNotAvailable = !camPermission || camPermission === "denied";

        const wrapperStyle: CSSProperties = this.props.ownVideoMinimized ? {
            display: localWrapperDisplayMode,
            width: 'inherit',
            margin: 'inherit',
            cursor: 'pointer',
        } : {
            cursor: !this.props.fullScreen ? 'pointer' : 'inherit',
            display: localWrapperDisplayMode,
        };

        return (
            <div className='local-wrapper'
                style={wrapperStyle}>
                <audio ref={this.remoteAudio} autoPlay={true}/>
                {cameraNotAvailable ?
                  this.renderCameraNotAvailable()
                  : this.renderVideoTag(flipLocal)}
                {micNotAvailable ? this.renderMicNotAvailable() : null}
                {!this.props.fullScreen ? this.MaximizeMinimizeFullscreen() : null}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState): FromStateProps => {
    const {
        app: {
            micPermission,
            camPermission,
            activeSource,
            alpha,
        },
        room: {
            name,
            meetingCode
        },
        remote: {
            client
        }
    } = state;

    return {
        ownName: name,
        activeSource,
        micPermission,
        camPermission,
        alpha,
        meetingCode,
        client,
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, {}, any>): FromDispatchProps => {
    return {
        setupSenderPeer: (name: string, video: HTMLVideoElement) =>
            dispatch(Client.Actions.setupSenderPeer(name, video)),

        setupScreenSenderPeer: (name: string, video: HTMLVideoElement) =>
            dispatch(Client.Actions.setupScreenSenderPeer(name, video)),

        registerSipSession: (session: JsSIP.RTCSession) =>
            dispatch({
                type: 'registerSipSession',
                session
            }),

        terminateSipSession: () =>
            dispatch({
                type: 'terminateSipSession'
            }),
    };
};

const LocalSession = connect(
    mapStateToProps,
    mapDispatchToProps
)(LocalSessionComponent);

export default LocalSession;
