import * as Immutable from "immutable";
import * as KurentoUtils from "kurento-utils";
import { Dispatch } from "react-redux";
import { ThunkDispatch } from 'redux-thunk';

import { GroupCallClient, SocketState } from "groupcall-client";
import * as App from "state/app";
import * as Room from "state/room";
import { RootState } from "state/store";
import {
    queryMicPermission,
    queryCameraPermission
} from 'app/utils/app-utils';
import { EVENT_STRINGS } from 'app/utils/segment-utils';
import segmentClient from 'clients/segment-client';
import airbrakeClient from 'app/airbrake/airbrake-client';

//console.log("Constraints", navigator.mediaDevices.getSupportedConstraints());

export interface RemotePeer {
    name:       string;
    peerObject: KurentoUtils.WebRtcPeer;
    connected:  boolean;
    audioMuted: boolean;
    videoMuted: boolean;
}

const RemotePeerRecord = Immutable.Record({
    name: '',
    peerObject: null,
    connected: false,
    audioMuted: false,
    videoMuted: false
});

export interface State {
    client:    GroupCallClient;
    peers:     Immutable.Map<string, RemotePeer>;
    status:    string;
    socketState: SocketState;
}

const StateRecord = Immutable.Record({
    client:    null,
    peers:     Immutable.Map<string, RemotePeer>(),
    status:    null,
    socketState: 'connecting'
});

interface RegisterClientAction {
    type:   string;
    client: GroupCallClient;
}

interface UnregisterClientAction {
    type: string;
}

interface PeerAction {
    type: string;
    name: string;
    peer: KurentoUtils.WebRtcPeer;
}

interface SocketStateAction {
    type: string;
    state: SocketState;
    reason: string;
    code: number;
}

interface SocketErrorAction {
    type: string;
    reason: string;
}

type Action = RegisterClientAction | UnregisterClientAction;

const initialState = new StateRecord();

const highVideoConstraints: boolean | {} = {
    width: { min: 352, ideal: 640, max: 640 },
    height: { ideal: 480, max: 480 },
    frameRate: { ideal: 20 }
};

const lowVideoConstraints: boolean | {} = {
    width: { min: 352, ideal: 480, max: 480 },
    height: { ideal: 360, max: 360 },
    frameRate: { ideal: 20 }
};

const mapClientToDispatch = (client: GroupCallClient, dispatch: ThunkDispatch<any, {}, any>) =>
    client.setCallbacks({
        updateSocketState: (state: SocketState, reason?: string, code?: number) =>
            dispatch(updateSocketState(state, reason, code)),

        onNewParticipant: (name) =>
            dispatch(Room.Actions.newParticipant(name)),

        onExistingParticipants: (names) =>
            dispatch(Room.Actions.existingParticipants(names)),

        onParticipantLeft: (name: string) =>
            dispatch(Room.Actions.participantLeft(name)),

        onReceiveVideoAnswer: (name: string, sdpAnswer: string) =>
            dispatch(receiveVideoResponse(name, sdpAnswer)),

        onRestartVideoFrom: (name: string) => {
            dispatch(Room.Actions.participantLeft(name));
            dispatch(Room.Actions.newParticipant(name));
        },

        onIceCandidate: (name: string, candidate: RTCIceCandidate) =>
            dispatch(receiveIceCandidate(name, candidate)),

        onRoomNotJoined: (room: string, message: string) =>
            dispatch(Room.Actions.roomNotJoined(room, message)),

        onRoomJoined: (room: string, name: string) =>
            dispatch(Room.Actions.roomJoined(room, name)),

        onLobbyInfo: (room: string, participants: number) =>
            dispatch(Room.Actions.lobbyInfo(room, participants)),

        onTrackDisabled: (name: string, kind: 'audio' | 'video') =>
            dispatch(disablePeerTrack(name, kind)),

        onTrackEnabled: (name: string, kind: 'audio' | 'video') => {
            dispatch(enablePeerTrack(name, kind));
        },

        onError: (reason: string) =>
            dispatch(connectionError(reason))
    });

export const reducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case "registerClient": {
            return state.set("client", (action as RegisterClientAction).client);
        }

        case "unregisterClient": {
            return state.delete("client");
        }

        case "registerPeer": {
            const peerAction = action as PeerAction;
            const remotePeer = new RemotePeerRecord({
                name: peerAction.name,
                peerObject: peerAction.peer
            });
            return state.update("peers", peers => peers.set(peerAction.name, remotePeer));
        }

        case "unregisterPeer": {
            return state.update("peers", peers => peers.delete((action as PeerAction).name));
        }

        case 'peerConnected': {
            const peerAction = action as PeerAction;
            return state.setIn(['peers', peerAction.name, 'connected'], true);
        }

        case 'peerDisconnected': {
            const peerAction = action as PeerAction;
            return state.setIn(['peers', peerAction.name, 'connected'], false);
        }

        case 'peerAudioMuted': {
            const peerAction = action as PeerAction;
            return state.setIn(['peers', peerAction.name, 'audioMuted'], true);
        }

        case 'peerAudioUnmuted': {
            const peerAction = action as PeerAction;
            return state.setIn(['peers', peerAction.name, 'audioMuted'], false);
        }

        case 'peerVideoMuted': {
            const peerAction = action as PeerAction;
            return state.setIn(['peers', peerAction.name, 'videoMuted'], true);
        }

        case 'peerVideoUnmuted': {
            const peerAction = action as PeerAction;
            return state.setIn(['peers', peerAction.name, 'videoMuted'], false);
        }

        case "newSocketState": {
            const socketStateAction = action as SocketStateAction;
            return state
                .set('socketState', socketStateAction.state);
        }

        case "error": {
            return state
                .set("status", (action as SocketErrorAction).reason);
        }

        default:
            return state;
    }
};

const receiveIceCandidate = (name: string, candidate: RTCIceCandidate) => (_dispatch: Dispatch<any>, getState: () => RootState) => {
    const {remote: {peers}} = getState();

    if (!peers.get(name)) {
        airbrakeClient.notify({
            error: `Remote peer missing`,
            params: { info: `receiveIceCandidate. There are ${peers.keySeq().count()} peers.` }
        });
        return;
    }
    peers.get(name).peerObject.addIceCandidate(candidate, error => {
        if (error) {
            console.error("Error adding ICE candidate", name, candidate, error);
        }
    });
};

const receiveVideoResponse = (name: string, sdpAnswer: string) => (_dispatch: Dispatch<any>, getState: () => RootState) => {
    const {remote: {peers}} = getState();

    if (!peers.get(name)) {
        airbrakeClient.notify({
            error: `Remote peer missing`,
            params: { info: `receiveVideoResponse. There are ${peers.keySeq().count()} peers.` }
        });
        return;
    }
    peers.get(name).peerObject.processAnswer(sdpAnswer, error => {
        if (error) {
            console.error("Failed to receive video response: '"+ name +"', '"+ sdpAnswer +"'", error);
        }
    });
};

const registerPeer = (name: string, peer: KurentoUtils.WebRtcPeer) => {
    return {
        type: "registerPeer",
        name: name,
        peer: peer
    };
};

const unregisterPeer = (name: string) => {
    return {
        type: "unregisterPeer",
        name: name
    };
};

const peerConnected = (name: string) => ({
    type: 'peerConnected',
    name: name
});

const peerDisconnected = (name: string) => ({
    type: 'peerDisconnected',
    name: name
});

const mutePeerAudio = (name: string) => ({
    type: 'peerAudioMuted',
    name: name
});

const unmutePeerAudio = (name: string) => ({
    type: 'peerAudioUnmuted',
    name: name
});

const mutePeerVideo = (name: string) => ({
    type: 'peerVideoMuted',
    name: name
});

const unmutePeerVideo = (name: string) => ({
    type: 'peerVideoUnmuted',
    name: name
});

const updateSocketState = (newState: SocketState, reason?: string, code?: number) => ({
    type: 'newSocketState',
    state: newState,
    reason: reason,
    code: code
});

const disablePeerTrack = (name: string, kind: 'audio' | 'video') => (dispatch: ThunkDispatch<any, {}, any>, getState: () => RootState) => {
    const { remote: { peers } } = getState();
    if (!peers.has(name)) {
        return;
    }
    if (kind === 'audio') {
        dispatch(mutePeerAudio(name));
    } else if (kind === 'video') {
        dispatch(mutePeerVideo(name));
    }
};

const enablePeerTrack = (name: string, kind: 'audio' | 'video') => (dispatch: ThunkDispatch<any, {}, any>, getState: () => RootState) => {
    const { remote: { peers } } = getState();
    if (!peers.has(name)) {
        return;
    }
    if (kind === 'audio') {
        dispatch(unmutePeerAudio(name));
    } else if (kind === 'video') {
        dispatch(unmutePeerVideo(name));
    }
};

const connectionError = (reason: string): SocketErrorAction => {
    return {
        type: "error",
        reason: reason
    }
};

const trackExists = (senders: RTCRtpSender[], trackKind: 'audio' | 'video'): boolean => {

    const trackKinds: string[] = senders
        .map((sender: RTCRtpSender) => sender.track)
        .filter(track => !!track)
        .map(track => track!.kind);

    if (trackKinds.includes(trackKind)) {
        return true;
    }
    return false;
};

/**
 * A function that sets a 'onended'-handler on the senders created when doing a
 * screen share. Should only be put on the senders when doing a screen share.
 * If the screen share is stopped by the browser, this handler executes, and it
 * re-activates the camera again.
 */
const setOnVideoEndedHandler = (senders: RTCRtpSender[], dispatch: ThunkDispatch<any, {}, any>) => {
    const videoTracks: MediaStreamTrack[] = senders
        .filter(s => !!s && !!s.track)
        .filter(s => s.track!.kind === 'video')
        .map(s => s.track!);

    // There should just be one video track
    if (videoTracks && videoTracks.length > 0) {
        videoTracks[0].onended = () => dispatch(Actions.activateCamera());
    } else {
        console.warn("Found no video track! Won't set onended-handler.");
    }
};

export const Actions = {
    register: (client: GroupCallClient) => (dispatch: Dispatch<any>) => {
        mapClientToDispatch(client, dispatch);

        dispatch({
            type: "registerClient",
            client: client
        });
    },

    unregister: () => ({
        type: "unregisterClient"
    }),

    setupSenderPeer: (name: string, video?: HTMLVideoElement) => async (dispatch: ThunkDispatch<any, {}, any>, getState: () => RootState) => {
        const {
            remote: {client, peers},
            room: { audio },
            app: { alpha }
        } = getState();

        if (peers.has(name)) {
            return;
        }

        const onIceCandidate = (candidate: RTCIceCandidate) =>
            client.sendIceCandidate(name, candidate);

        const offerToReceiveVideo = (_: any, sdpOffer: string) =>
            client.sendOfferToReceiveVideo(name, sdpOffer);

        let audioConstraint: boolean = true;
        let videoConstraint: boolean | {} = highVideoConstraints;

        let micPermission: App.PermissionState;
        let camPermission: App.PermissionState;
        try {
            micPermission = await queryMicPermission();
            if (micPermission === 'denied') {
                audioConstraint = false;
                dispatch(App.Actions.setMicPermission('denied'));
            }

            camPermission = await queryCameraPermission();
            if (camPermission === 'denied') {
                videoConstraint = false;
                dispatch(App.Actions.setCamPermission('denied'));
            }
        } catch (error) {
            // Not chrome, FireFox still potential candidate
        }

        const options = {
            localVideo: video,
            mediaConstraints: {
                audio: alpha ? false : audioConstraint,
                video: videoConstraint
            },
            onicecandidate: onIceCandidate,
            //sendSource: "screen" // See comment at the end of this file to use this alternate way
        };

        const peer = KurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options,
            function (error) {
                if (error) {
                    // Error setting up user media - show error message about permissions
                    dispatch(App.Actions.setCamPermission('denied'));
                    client.sendTrackDisabled('video');

                    console.group("Error setting up Sendonly");
                    // @ts-ignore
                    console.error(`${error.name} - ${error.message}`);
                    console.error("Peer:", peer);
                    console.groupEnd();
                } else {
                    peer.generateOffer(offerToReceiveVideo);

                }
                const peerConnection = peer.peerConnection;
                const sendCapabilities = () => {
                    const senders: RTCRtpSender[] = peerConnection.getSenders();
                    const videoEnabled = trackExists(senders, 'video');
                    if (!videoEnabled) {
                        client.sendTrackDisabled('video');
                    }
                    client.sendMediaCapabilities(true , videoEnabled);
                };
                peerConnection.oniceconnectionstatechange = () => {
                    if (peerConnection.iceConnectionState === 'connected') {
                        sendCapabilities();

                        // If we are muted before we set up the stream, mute us again.
                        dispatch(Actions.setOwnMicrophoneMuted(!audio));
                    }
                }
            }
        );
        dispatch(registerPeer(name, peer));
    },

    activateScreenSharing: (source: "screen" | "window" | "application" | "desktop") => (dispatch: ThunkDispatch<any, {}, any>, getState: () => RootState) => {
        const {room: {room, name}, remote: {client}} = getState();

        dispatch(Actions.teardownPeer(name));
        dispatch(() => client.sendRestartVideo(name));
        dispatch(App.Actions.activateSource(source));

        segmentClient.track(EVENT_STRINGS.SHARED_SCREEN, {
            roomId: room,
            userName: name,
            localVideoSource: source
        });
    },

    activateCamera: () => (dispatch: ThunkDispatch<any, {}, any>, getState: () => RootState) => {
        const {room: {room, name}, remote: {client}} = getState();

        dispatch(Actions.teardownPeer(name));
        dispatch(() => client.sendRestartVideo(name));
        dispatch(App.Actions.activateSource("camera"));

        segmentClient.track(EVENT_STRINGS.ACTIVATED_CAMERA, {
            roomId: room,
            userName: name,
            localVideoSource: "camera"
        });
    },

    setupScreenSenderPeer: (name: string, video?: HTMLVideoElement) => (dispatch: ThunkDispatch<any, {}, any>, getState: () => RootState) => {
        const {
            remote: {client, peers},
            app,
            room: { audio }
        } = getState();

        if (peers.has(name)) {
            console.warn(`Peer exists: "${name}"`);

            return;
        }

        const screenConstraints = (() => {
            if (app.mediaSourceSupport) {
                return {
                    mozMediaSource: app.activeSource,
                    mediaSource: app.activeSource
                };
            } else if (app.chromeExtensionExists) {
                return {
                    mandatory: {
                        chromeMediaSource: "desktop",
                        chromeMediaSourceId: app.chromeMediaSourceId,
                        minWidth: 320,
                        maxWidth: screen.width > 1920 ? screen.width : 1920
                    },
                    optional: []
                };
            } else {
                return null;
            }
        })();

        if (!screenConstraints) {
            console.warn(`Unable to create screen constraints for "${name}. Aborting.`);

            return;
        }

        const onIceCandidate = (candidate: RTCIceCandidate) =>
            client.sendIceCandidate(name, candidate);

        const offerToReceiveVideo = (_: any, sdpOffer: string) =>
            client.sendOfferToReceiveVideo(name, sdpOffer);

        const options = {
            localVideo: video,
            mediaConstraints: {
                video: screenConstraints
            },
            onicecandidate: onIceCandidate,
            //sendSource: "screen" // See comment at the end of this file to use this alternate way
        };

        // This if-case is to prevent dual sound in the tel/vidco solution
        // Can't add audio param unless it is set to false - hence added outside of options
        if (app.alpha) {
            // @ts-ignore
            options.mediaConstraints.audio = false;
        }

        const peer = KurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options,
            async function (error) {
                if (error) {
                    console.error("Error setting up Sendonly", peer, error);
                } else {
                    // @ts-ignore
                    peer.peerConnection.addStream(await navigator.mediaDevices.getUserMedia({ audio: true }));

                    // Set handlers that take care of when the video ends,
                    // in case the browsers shuts it down
                    setOnVideoEndedHandler(peer.peerConnection.getSenders(), dispatch);

                    // Audio gets enabled when we set up a new sender with the params above, and the audio: true flag
                    // needs to be enabled. Hence we send that the audio is muted after setup.
                    dispatch(Actions.setOwnMicrophoneMuted(!audio));

                    peer.generateOffer(offerToReceiveVideo);
                }
            }
        );

        dispatch(registerPeer(name, peer));
    },

    setupReceiverPeer: (name: string, video?: HTMLVideoElement) => (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {
            remote: {client, peers},
            app
        } = getState();

        if (peers.has(name)) {
            return;
        }

        const onIceCandidate = (candidate: RTCIceCandidate) =>
            client.sendIceCandidate(name, candidate);

        const offerToReceiveVideo = (_: any, sdpOffer: string) =>
            client.sendOfferToReceiveVideo(name, sdpOffer);

        let videoConstraint: boolean | {} = highVideoConstraints;
        const options = {
            remoteVideo: video,
            mediaConstraints: {
                audio: !app.alpha,
                video: videoConstraint
            },
            onicecandidate: onIceCandidate
        };

        const peer = KurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, function (error) {
            if (error) {
                console.error("Something failed", error);
            } else {
                const peerConnection = peer.peerConnection;
                peer.generateOffer(offerToReceiveVideo);
                peerConnection.onconnectionstatechange = () => {
                    if (peerConnection.connectionState === 'connected') {
                        dispatch(peerConnected(name));
                    } else if (peerConnection.connectionState === 'closed' || peerConnection.connectionState === 'failed') {
                        dispatch(peerDisconnected(name));
                    }
                };
            }
        });

        dispatch(registerPeer(name, peer));
    },

    setOwnMicrophoneMuted: (muted: boolean) => (_dispatch: Dispatch<any>, getState: () => RootState) => {
        const { remote: { peers } } = getState();

        peers.map(peer => peer!.peerObject)
            .filter((peer) => (peer instanceof KurentoUtils.WebRtcPeer.WebRtcPeerSendonly))
            .forEach((peer) => {
                // The Kurento implementation is missing the
                // audioEnabled attribute in the type mapping
                (peer as any).audioEnabled = !muted;
            });
        // We could imagine sending "trackDisabled" on the signaling channel here.
        // However, that leads to complications with the video-tag being dismounted
        // from the DOM, and the reference to the stream being lost.
    },

    setOwnVideoMuted: (muted: boolean) => (_dispatch: Dispatch<any>, getState: () => RootState) => {
        const { remote: { peers } } = getState();

        peers.map(peer => peer!.peerObject)
            .filter((peer) => (peer instanceof KurentoUtils.WebRtcPeer.WebRtcPeerSendonly))
            .forEach((peer) => {
                // The Kurento implementation is missing the
                // videoEnabled attribute in the type mapping
                (peer as any).videoEnabled = !muted;
            });
        // We could imagine sending "trackEnabled" on the signaling channel here.
        // That leads to similar complications as for the trackDisabled, and we're
        // not doing it for now.
    },

    setLowerVideoQuality: () => (_dispatch: Dispatch<any>, getState: () => RootState) => {
        const { remote: { peers } } = getState();
        peers.map(peer => peer!.peerObject)
            .filter((peer) => (peer instanceof KurentoUtils.WebRtcPeer.WebRtcPeerSendonly))
            .forEach((peer) => {
                try {
                    peer?.getLocalStream().getVideoTracks().forEach(video => {
                        video.applyConstraints(lowVideoConstraints);
                    });
                } catch (error) {
                    // Catch FF error
                    // Peer connection might not be open on FF and hence this will cause an error
                    // Only thing is that that stream wont lower res, small (to none) issue
                    console.error(error);
                }
            });
    },

    teardownPeer: (peerName: string) => (dispatch: Dispatch<any>, getState: () => RootState) => {
        const {room: {name}, remote: {client, peers}} = getState();
        const peer = peers.get(peerName);

        if (name !== peerName) {
            client.sendCancelVideoFrom(peerName);
        }

        if (peer) {
            peer.peerObject.dispose();

            dispatch(unregisterPeer(peerName));
        } else {
            console.warn(`Failed to locate peer when tearing down: ${name}`);
        }
    }
};

/* If you feel like using "sendSource: 'screen'"

const isFirefox = false;
const chromeMediaSource = "desktop";

(window as any).getScreenConstraints = (_sendSource: string, callback: (_: any, constraints: any) => void) => {
    var firefoxScreenConstraints = {
        mozMediaSource: 'window',
        mediaSource: 'window'
    };

    if (isFirefox) {
        callback(null, firefoxScreenConstraints);

        return;
    }

    const {app: {sourceId}} = store.getState();

    var screen_constraints = {
        mandatory: {
            chromeMediaSource: chromeMediaSource,
            chromeMediaSourceId: sourceId,
            maxWidth: screen.width > 1920 ? screen.width : 1920,
            maxHeight: screen.height > 1080 ? screen.height : 1080
        },
        optional: []
    };

    callback(null, {video: screen_constraints});
}
*/
