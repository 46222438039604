import * as Immutable from "immutable";
import { ThunkDispatch } from 'redux-thunk';

import { RootState } from "state/store";
import segmentClient from 'clients/segment-client';
import { EVENT_STRINGS } from 'app/utils/segment-utils';

export interface State {
    room:              string;
    name:              string;
    participants:      Immutable.List<string>;
    error:             string;
    lobbyParticipants: number;
    state?:            "room" | "lobby";
    audio: boolean;
    session: any;
    meetingCode: string;
    pin: string;
    conferenceMembers: Array<any>;
}

interface RoomAction {
    type:  string;
    room?: string;
    name?: string;
    reason?: string;
}

interface LobbyAction {
    type:         string;
    room:         string;
    participants: number;
}

interface AudioAction {
    type:  string;
    audio: boolean;
}

interface SessionAction {
    type:  string;
    session: JsSIP.RTCSession;
}

interface ExistingParticipantsAction extends RoomAction {
    readonly participants: Immutable.List<string>;
}

const StateRecord = Immutable.Record({
    room:              null,
    name:              null,
    participants:      Immutable.List<string>(),
    error:             null,
    lobbyParticipants: 0,
    state:             "lobby",
    audio:             true,
    session: undefined,
    meetingCode: null,
    pin: null,
    conferenceMembers: [],
});

const initialState = new StateRecord();

export const reducer = (state = initialState, action: (RoomAction | LobbyAction)) => {
    switch (action.type) {
        case "enterLobby": {
            return state
                .set("state", "lobby")
                .set("room", action.room);
        }

        case "roomJoined": {
            return state
                .set("state", "room")
                .set("name", (action as RoomAction).name);
        }

        case "leaveRoom": {
            return state.delete("state");
        }

        case "clearError": {
            return state.delete("error");
        }

        case "setError": {
            return state.set("error", (action as RoomAction).reason);
        }

        case "newParticipant": {
            return state.update("participants", ps => ps.push((action as RoomAction).name).sort());
        }

        case "existingParticipants": {
            return state.set("participants", (action as ExistingParticipantsAction).participants.sort());
        }

        case "participantLeft": {
            return state.update("participants", ps => {
                return ps.filter((name: string) => name !== (action as RoomAction).name);
            });
        }

        case "lobbyInfo": {
            return state.set("lobbyParticipants", (action as LobbyAction).participants);
        }

        case "toggleAudio": {
            const session = state.get('session');
            if (session) {
                if (session.isMuted().audio) {
                    session.unmute({ audio:true })
                } else {
                    session.mute({ audio:true })
                }
            }
            return state.set('audio', (action as AudioAction).audio);
        }

        case 'registerSipSession': {
            return state.set('session', (action as SessionAction).session);
        }

        case 'terminateSipSession': {
            const session: JsSIP.RTCSession  = state.get('session');
            if (session) {
                if (session.isEnded()) {
                    console.warn("Tried to terminate an already ended session");
                } else {
                    session.terminate();
                }
            }
            return state.delete('session');
        }

        case "setMeetingCode": {
            return state.set("meetingCode", (action as any).meetingCode);
        }

        case "setPin": {
            return state.set("pin", (action as any).pin);
        }

        case 'setConferenceData': {
            return state
                .set('pin', (action as any).pin)
                .set('conferenceMembers', (action as any).conferenceMembers);
        }

        default:
            return state;
    }
};

const joinRoom =
        (room: string, name: string) =>
        (dispatch: ThunkDispatch<any, {}, any>, getState: (() => RootState)) => {
    const {remote: {client}} = getState();

    dispatch({
        type: "clearError",
    });

    dispatch(() => client.joinRoom(room, name));
    segmentClient.track(EVENT_STRINGS.JOINED_ROOM, {
        roomId: room,
        userName: name
    });
};

const enterLobby = 
        (room: string) =>
        (dispatch: ThunkDispatch<any, {}, any>, getState: (() => RootState)) => {
    const {remote: {client}} = getState();

    dispatch(() => client.enterLobby(room));
    dispatch({
        type: "enterLobby",
        room: room
    });
};

const roomJoined = (room: string, name: string) => {
    return {
        type: "roomJoined",
        room: room,
        name: name
    };
};

const leaveRoom = () => (dispatch: ThunkDispatch<any, {}, any>, getState: (() => RootState)) => {
    const {room: {room, name}, remote: {client}} = getState();

    dispatch(() => client.leaveRoom());
    dispatch({
        type: "leaveRoom"
    });
    dispatch( { type: 'terminateSipSession' });
    segmentClient.track(EVENT_STRINGS.LEFT_ROOM, {
        roomId: room,
        userName: name
    });
};

const requestPin = () => async (dispatch: ThunkDispatch<any, {}, any>, getState: (() => RootState)) => {
    const {room: { meetingCode }} = getState();
    if (!meetingCode) {
        return;
    }

    try {
        const fetchUrl = `https://home.telavox.se/api/external/conference/${meetingCode}/pin`;
        const res = await fetch(fetchUrl, { method: 'post' });
        const data = await res.json();
        dispatch({
            type: 'setPin',
            pin: data.pin
        })
    } catch (e) {
        console.error('Error when trying to request pin: ', e);
    }

    // TODO: Request pin - add a Segment event?

};

const newParticipant = (name: string): RoomAction => {
    return {
        type: "newParticipant",
        name: name
    };
};

const existingParticipants = (names: Array<string>): ExistingParticipantsAction => {
    return {
        type: "existingParticipants",
        participants: Immutable.List<string>(names)
    };
};

const participantLeft = (name: string): RoomAction => {
    return {
        type: "participantLeft",
        name: name
    };
};

const roomNotJoined = (_room: string, reason: string): RoomAction => {
    return {
        type: "setError",
        reason: reason
    };
};

const lobbyInfo = (room: string, participants: number): LobbyAction => {
    return {
        type: "lobbyInfo",
        room: room,
        participants: participants
    };
};

const close = () => {
    return {
        type: "close"
    }
};

const toggleAudio = (bool: boolean) : AudioAction => {
    return {
        type: 'toggleAudio',
        audio: bool
    }
};

const setMeetingCode = (meetingCode: string) => {
    return {
        type: "setMeetingCode",
        meetingCode
    }
};

const setConferenceData = (pin: string, conferenceMembers: Array<any>) => {
    return {
        type: 'setConferenceData',
        pin,
        conferenceMembers
    }
};

export const Actions = {
    enterLobby,
    lobbyInfo,
    joinRoom,
    leaveRoom,
    requestPin,
    newParticipant,
    existingParticipants,
    participantLeft,
    roomNotJoined,
    roomJoined,
    close,
    toggleAudio,
    setMeetingCode,
    setConferenceData,
};
