import * as React from "react";

import { AlertDialog } from 'app/component/dialog/alert-dialog';

const TITLE_TEXT = "Share screen";
const BODY_TEXT = "In order to share your screen you need to install our Chrome Extension";
const OK_BUTTON_TEXT = "Go to Extension";
const DISMISS_BUTTON_TEXT = "Dismiss";

interface Props {
    isOpen:                 boolean,
    handleButtonClicked:    () => void
}

export const ChromeExtensionDialog = ({isOpen, handleButtonClicked} : Props) => {

    const okClicked = () => {
        window.open('https://chrome.google.com/webstore/detail/flow-screen-sharing/hajcgcfghpaioaicinacgidilhgebglj');
        handleButtonClicked();
    };

    return (
        <AlertDialog
          isOpen={isOpen}
          title={TITLE_TEXT}
          bodyText={BODY_TEXT}
          affirmativeText={OK_BUTTON_TEXT}
          handleAffirmative={okClicked}
          negativeText={DISMISS_BUTTON_TEXT}
          handleNegative={handleButtonClicked}
        />
    );
};