import * as React from "react";

interface InputProps {
    alpha?: string;
    participants: Array<any>;
    toggleShowParticipants: () => void;
    open: boolean
}

interface ComponentState {
    open:  boolean;
}

const EXTERNAL_SIP_NUMBER = '+4613244880';

export class ParticipantTab extends React.PureComponent<InputProps, ComponentState> {
    constructor(props: InputProps) {
        super(props);

        this.state = {
            open: false,
        };
    }

    public render() {

        return (
            <div className={`participant-tab ${this.props.open ? 'open': ''}`}>
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: 24 }}>
                    <div className={'user-icon close'} onClick={this.props.toggleShowParticipants}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                    </div>
                    <div>
                        {'All participants:'}
                    </div>
                </div>
                <div>
                    <div>
                        {this.props.participants
                            .sort((a: any, b: any) => a.joinTime - b.joinTime)
                            .map(participant => {
                                const memberName = participant && participant.displayName;
                                const uuidCheck = new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-5][0-9a-fA-F]{3}-[089abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$');
                                const displayName = uuidCheck.test(memberName && memberName.slice(0, 36)) ? memberName && memberName.slice(37) : '';

                                return (
                                    <div key={participant.key} style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className={'user-icon'}>
                                            {participant.callerId !== EXTERNAL_SIP_NUMBER ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="32px" height="32px"><path d="M0 0h24v24H0z" fill="none"/><path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"/></svg>
                                                ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="36px" height="36px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/></svg>
                                                )}
                                        </div>
                                        <div>{displayName}</div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
        )
    }
}
