import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from 'redux-thunk';

import * as App from "state/app";
import * as Client from "state/client";
import * as Room from "state/room";
import { RootState } from "state/store";
import { ControlPanel } from "app/component/control-panel";
import { VideoSession } from "app/component/video-session";
import { ChromeExtensionDialog } from 'app/component/dialog/chrome-extension-dialog';
import { isChrome, isMobileOrTablet, queryCameraPermission, queryMicPermission } from 'app/utils/app-utils';
import { fetchConferenceDataFromEndpoint } from 'app/utils/conference-utils';
import { BetaInfo } from 'app/component/beta/beta-info';
import { ParticipantTab } from 'app/component/participant-view/participant-tab';
import { JoinInfo } from 'app/component/join-info';
import {GroupCallClient} from "../../groupcall-client";

// This is to have webpack bundle the sound file
require('sounds/beep.wav');

export { ConnectedRoomComponent as Room };

const Group: React.SFC<{}> = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"/>
    </svg>
};

interface InputProps {
    beta?: string;
}

interface FromStateProps {
    room: Room.State;
    app:  App.State;
    micPermission: App.PermissionState;
    camPermission: App.PermissionState;
    alpha: boolean;
    meetingCode: string;
    pin: string;
    client: GroupCallClient;
    activeSource: string;
}

interface FromDispatchProps {
    leaveRoom:             () => void;
    teardownPeer:          (name: string) => void;
    selectScreenDevice:    (source: "screen" | "window" | "application" | "desktop") => void;
    selectCameraDevice:    () => void;
    setMicrophoneMuted:    (muted: boolean) => void;
    setVideoMuted:         (muted: boolean) => void;
    setLowerVideoQuality:  () => void;
    requestPin:  () => void;
}

interface ComponentState {
    fullscreen?:                string;
    videoMuted:                 boolean;
    deviceSelection:            "show" | "hide";
    chromeExtensionDialogOpen:  boolean;
    ownVideoMinimized:  boolean;
    ownVideoInteracted:  boolean;
    interval: any;
    participants: Array<any>;
    showParticipants:  boolean;
    showJoinInfo: boolean;
    isMobile:  boolean;
    closedErrorBar:  boolean;
}

type Props = InputProps & FromStateProps & FromDispatchProps;

class RoomComponent extends React.PureComponent<Props, ComponentState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            fullscreen: undefined,
            videoMuted: false,
            deviceSelection: "hide",
            chromeExtensionDialogOpen: false,
            ownVideoMinimized: false,
            ownVideoInteracted: false,
            interval: undefined,
            participants: [],
            showParticipants: false,
            showJoinInfo: false,
            isMobile: isMobileOrTablet(),
            closedErrorBar: false,
        };
        this.fetchConferenceParticipants = this.fetchConferenceParticipants.bind(this)
    }

    componentDidUpdate(prevProps: Props) {
        const prevSize = prevProps.room.participants.size;
        const nowParticipants = this.props.room.participants;
        const nowSize = nowParticipants.size;

        if (nowSize > prevSize) {
            // More users in the room, play sound
            const audioElement: HTMLMediaElement | null = document && (document.getElementById('notification') as HTMLMediaElement);
            const playPromise = audioElement && audioElement.play();
            if (playPromise) {
                playPromise.then( () => console.log('Notifier pling') ).catch(e => console.error(e));
            }
        }

        // If we go from a larger size to a smaller room while not
        // interacting with the local video, maximize local stream
        if (nowSize === 4 && prevSize === 5 && !this.state.ownVideoInteracted) {
            this.setState({ ownVideoMinimized: false });
        }

        // If we go from a small size room to a larger room
        if (nowSize > 4 && prevSize < nowSize && this.props.activeSource !== 'desktop' ) {
            // Lower the stream quality if there are more than 4 in the room and keep it that way
            this.setLowerVideoQuality();

            // Minimize the local stream if it hasnt been interacted with
            if (!this.state.ownVideoMinimized && !this.state.ownVideoInteracted) {
                this.setState({ ownVideoMinimized: true })
            }
        }

        // If the room shrinks
        if (nowSize < prevSize) {
            const fullscreenedParticipant = this.state.fullscreen || "";
            // The participant that was "fullscreened" might have left
            if (!nowParticipants.contains(fullscreenedParticipant)) {
                // Clear out the fullscreen state
                this.setState({ fullscreen: undefined });
            }
        }
    }

    public async fetchConferenceParticipants() {
        const meetingCode = this.props.meetingCode;
        const result = await fetchConferenceDataFromEndpoint({ endpoint: 'members', meetingCode });

        this.setState({ participants: result });
    }

    public async componentDidMount() {
        await this.fetchConferenceParticipants();
        const interval = setInterval(this.fetchConferenceParticipants, 3000);
        this.setState({ interval });
    }

    public componentWillUnmount() {
        clearInterval(this.state.interval);
        this.props.teardownPeer(this.props.room.name);
    }

    private errorBar = () => {
        const micPermission = this.props.micPermission === "denied";
        const videoPermission = this.props.camPermission === "denied";

        const disabledPermissions = micPermission || videoPermission;
        const isMobile = this.state.isMobile;

        const singleDisabledUnit = videoPermission ? 'the video device' : 'the microphone';
        const errorDevices = micPermission && videoPermission ? 'the video device and the microphone' : singleDisabledUnit;
        const permissionErrorText = `You have not given us permission to use ${errorDevices}. Look over your browser or system permissions.`;

        const mobileErrorText = 'For the best experience, use the latest version of Chrome on a desktop device';

        const errorTexts = [];
        disabledPermissions ? errorTexts.push(permissionErrorText) : undefined;
        isMobile ? errorTexts.push(mobileErrorText) : undefined;
        if (errorTexts.length < 1 || this.state.closedErrorBar === true) {
            return null;
        }

        const setClosedErrorBar = () => {
            this.setState({ closedErrorBar: true });
        };

        return (
            <div className='error-bar' style={{ display: this.state.closedErrorBar ? 'none' : undefined }}>
                {errorTexts.map(error => {
                    return (
                        <div style={{ padding: 4 }}>
                            {error}
                        </div>
                    )
                })}
                <div style={{ cursor: 'pointer' }} onClick={setClosedErrorBar}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#24242" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                </div>
            </div>
        )
    };

    private setChromeExtensionDialogOpen = (open: boolean) => {
        this.setState({ chromeExtensionDialogOpen: open });
    };

    public render() {
        const {
            room: { participants,
                audio,
                meetingCode,
                pin
            },
            app: { chromeExtensionExists, mediaSourceSupport },
            alpha,
            requestPin
        } = this.props;
        const { fullscreen, chromeExtensionDialogOpen } = this.state;

        const clazz = !!fullscreen ? "remotes-1" : `remotes-${participants.size}`;
        const ssSupported = chromeExtensionExists || mediaSourceSupport;
        const chromeExtensionNeededForScreensharing = isChrome() && !chromeExtensionExists;
        const moreThanTwoParticipants = participants.size > 1;
        const showAllButtonDisplay = !!fullscreen;
        const showAllButtonText = moreThanTwoParticipants ? "Show all (" + participants.size + ")" : "Show local video";

        return (
            <React.Fragment>
                <div className="room">
                {/* request the audio file in the src-attribute of the audio tag */}
                <audio style={{ display: 'none' }} id={'notification'} src='sounds/beep.wav' crossOrigin={'anonymous'}/>
                <div className={'top-icon-holder'}>
                    <BetaInfo beta={this.props.beta}/>
                    {alpha ? (
                        <React.Fragment>
                            <div className={`participant-icon ${this.state.showParticipants ? 'hidden': ''}`} onClick={this.toggleShowParticipants}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24px" height="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"/></svg>
                                <div>{(this.state.participants && this.state.participants.length) || ''}</div>
                            </div>
                            <ParticipantTab
                                participants={this.state.participants}
                                toggleShowParticipants={this.toggleShowParticipants}
                                open={this.state.showParticipants}
                            />
                        </React.Fragment>
                    ) : null}
                </div>

                {this.errorBar()}

                <div className="remote-streams">
                    <div className={clazz}>
                        {this.props.room.participants.map((participant, index) => {

                            return (
                                <VideoSession
                                  key={participant!}
                                  name={participant!}
                                  onToggleFullscreen={this.toggleFullscreen}
                                  fullscreen={fullscreen}
                                  position={index ? index : 0}
                                  noOfParticipants={participants.size}
                                />
                            );
                        })}

                        {this.props.room.participants.isEmpty() ? <div>No other participants</div> : null}
                    </div>
                </div>
                <div className="local-session-control-panel-wrapper">
                    {showAllButtonDisplay ?
                        <div className={'showAll-box'}>
                            <button className={'showAll-button'} onClick={this.handleShowAll}>
                                <div className='showAll-button-icon'><Group /></div>
                                <div>{showAllButtonText}</div>
                            </button>
                        </div> : null
                    }

                    <ControlPanel
                       selectScreenDevice={ssSupported ? this.props.selectScreenDevice : undefined}
                       selectCameraDevice={this.props.selectCameraDevice}
                       toggleAudioMute={this.toggleAudioMute}
                       toggleVideoMute={this.toggleVideoMute}
                       toggleCamera={() => console.log("camera")}
                       leaveRoom={this.props.leaveRoom}
                       audioMuted={!audio}
                       videoMuted={this.state.videoMuted}
                       activeSource={this.props.app.activeSource}
                       chromeExtReqForScreenSharing={chromeExtensionNeededForScreensharing}
                       displayChromeExtensionDialog={() => this.setChromeExtensionDialogOpen(true)}
                       ownVideoMinimized={this.state.ownVideoMinimized}
                       toggleOwnStreamCallback={this.toggleOwnStream}
                       fullScreen={!!fullscreen}
                       toggleShowJoinInfo={this.toggleShowJoinInfo}
                    />
                </div>
                {isChrome() ?
                    <ChromeExtensionDialog
                      isOpen={chromeExtensionDialogOpen}
                      handleButtonClicked={() => this.setChromeExtensionDialogOpen(false)}
                    />
                    : null
                }
                </div>
                {this.state.showJoinInfo ? (
                    <JoinInfo
                        pin={pin}
                        meetingCode={meetingCode}
                        toggleShowJoinInfo={this.toggleShowJoinInfo}
                        requestPin={requestPin}
                    />
                ) : null}
            </React.Fragment>
        );

    }

    private toggleShowJoinInfo = () => {
        this.setState({
            showJoinInfo: !this.state.showJoinInfo
        });
    };

    private toggleOwnStream = () => {
        this.setState({
            ownVideoMinimized: !this.state.ownVideoMinimized,
            ownVideoInteracted: true
        });
    };

    private handleShowAll = () =>
        this.setState({
        fullscreen: undefined
    });

    private toggleShowParticipants = () => {
        this.setState({
            showParticipants: !this.state.showParticipants,
            ownVideoMinimized: !this.state.showParticipants ? true : this.state.ownVideoMinimized
        });
    };

    private toggleFullscreen = (participant: string) =>
        this.setState({
            fullscreen: (!!this.state.fullscreen) ? undefined : participant,
            ownVideoMinimized: true
        });

    private toggleAudioMute = () => {
        // If we have audio (audio == 'true'), we should mute (muteAudio == 'true')
        // If we do not have audio (audio == 'false'), we should unmute (muteAudio == 'false')
        const muteAudio = this.props.room.audio;
        this.props.setMicrophoneMuted(muteAudio);
        this.sendToggleTrack(!muteAudio, 'audio');
    };

    private toggleVideoMute = () => {
        const videoMuted = !this.state.videoMuted;
        this.props.setVideoMuted(videoMuted);
        this.setState({
            videoMuted
        });
        this.sendToggleTrack(!videoMuted, 'video');
    };

    private setLowerVideoQuality = () => {
        this.props.setLowerVideoQuality();
    };

    private sendToggleTrack = async (toogle: boolean, media: string) => {
        // TODO: Check permissions first
        let queryResult;
        if (media === 'audio') {
            queryResult = await queryMicPermission();
        } else if (media === 'video') {
            queryResult = await queryCameraPermission();
        }
        if (queryResult !== 'granted') {
            return;
        }
        if (toogle) {
            this.props.client.sendTrackEnabled(media);
        } else {
            this.props.client.sendTrackDisabled(media);
        }
    }
}

const mapStateToProps = (state: RootState): FromStateProps => {
    const {
        app: {
            micPermission,
            camPermission,
            alpha,
            activeSource
        },
        room: {
            meetingCode,
            pin
        },
        remote: {
            client
        }
    } = state;

    return {
        room: state.room,
        app: state.app,
        micPermission,
        camPermission,
        alpha,
        meetingCode,
        pin,
        client,
        activeSource
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, {}, any>): FromDispatchProps => {
    return {
        leaveRoom: () =>
            dispatch(Room.Actions.leaveRoom()),

        teardownPeer: (name) =>
            dispatch(Client.Actions.teardownPeer(name)),

        selectScreenDevice: (source) => {
            if (source === "desktop") {
                dispatch(App.Actions.selectSourceId());
            } else {
                dispatch(Client.Actions.activateScreenSharing(source));
            }
        },

        selectCameraDevice: () =>
            dispatch(Client.Actions.activateCamera()),

        setMicrophoneMuted: (muted) => {
            dispatch(Room.Actions.toggleAudio(!muted));
            dispatch(Client.Actions.setOwnMicrophoneMuted(muted));
        },

        setVideoMuted: (muted) =>
            dispatch(Client.Actions.setOwnVideoMuted(muted)),

        setLowerVideoQuality: () =>
            dispatch(Client.Actions.setLowerVideoQuality()),
        requestPin: () => {
            dispatch(Room.Actions.requestPin());
        }
    };
};

const ConnectedRoomComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(RoomComponent);
