import * as React from "react";
import { COUNTRY_NUMBER_LIST } from 'app/utils/conference-utils';
const QRCode = require('qrcode.react');

interface InputProps {
    pin?: string;
    meetingCode: string;
    toggleShowJoinInfo: () => void;
    requestPin: () => void;
}

interface ComponentState {
    country: string;
    number: string;
}

type Props = InputProps & ComponentState;

export class JoinInfo extends React.PureComponent<InputProps, ComponentState> {
    readonly linkRef: React.RefObject<any>;
    constructor(props: Props) {
        super(props);
        this.linkRef = React.createRef();
        const defaultCountry = 'Sweden';

        this.state = {
            country: defaultCountry,
            number: COUNTRY_NUMBER_LIST.filter(o => o.country === defaultCountry)[0].number
        }
    }
    componentDidMount() {
        const url = "https://video-conference.joinflow.com/?room=";
        const urlLink = `${url}${this.props.meetingCode}`;
        this.linkRef.current.value = urlLink
    }

    copyToClipboard = () => {
        this.linkRef.current.select();
        document.execCommand('copy');
    };

    public render() {
        const pin = this.props.pin && `${this.props.pin.slice(0, 3)} ${this.props.pin.slice(3)}`;

        return (
            <div className={'join-modal'}>
                <div className={'join-box'}>
                    <div>
                        <div className={'title'}>
                            Joining info
                        </div>

                        <select
                            className={'select'}
                            name={'country'}
                            value={this.state.country}
                            onChange={(e) => {
                                this.setState({ country: e.target.value });
                                this.setState({ number: COUNTRY_NUMBER_LIST.filter(o => o.country === e.target.value)[0].number });
                            }
                        }>
                            {COUNTRY_NUMBER_LIST.map((option:any) => {
                                return (
                                    <option
                                      value={option.country}
                                      key={option.country}
                                    >
                                        {option.country}
                                    </option>
                                );
                            })}
                        </select>

                        <div className={'join-info'}>
                            <div className={'input-box'}>
                                <div className={'sub-title'}>Number:</div>
                                <input className={'input'} type='text' value={this.state.number} readOnly/>

                                <div className={'sub-title'}>Pin code:</div>
                                {pin ? (
                                    <input className={'input'} type='text' value={pin} readOnly/>
                                ) : (
                                    <div className={'request-pin'} onClick={this.props.requestPin}>Request pin</div>
                                )}

                                <div className={'sub-title'}>Video link:</div>
                                <input className={'input'} type='text' ref={this.linkRef} readOnly/>
                            </div>
                            <div>
                                <div className={'sub-title'}>QR code:</div>
                                <div>
                                    <QRCode value={`${this.state.number},${this.props.pin}`} size={156}/>
                                </div>
                            </div>
                        </div>
                        <div className={'button-wrapper'}>
                            <button className={'button'} onClick={this.copyToClipboard}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4l6 6v10c0 1.1-.9 2-2 2H7.99C6.89 23 6 22.1 6 21l.01-14c0-1.1.89-2 1.99-2h7zm-1 7h5.5L14 6.5V12z"/></svg>
                                Copy info
                            </button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div className={'close'} onClick={this.props.toggleShowJoinInfo}>Close</div>
                    </div>
                </div>
            </div>
        )
    }
}
