import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from 'redux-thunk';
// @ts-ignore
import * as uuidv4 from 'uuid/v4';

import * as Room from "state/room";
import { RootState } from "state/store";
import { BetaInfo } from './beta/beta-info';
import { isMobileOrTablet } from '../utils/app-utils';

interface InputProps {
    roomName:  string;
    userName?: string;
    beta?: string;
}

interface DispatchProps {
    joinRoom:   (roomName: string, userName: string) => void;
    enterLobby: (roomName: string) => void;
}

interface FromStateProps {
    error:        string;
    participants: number;
    conferenceMembers: Array<any>;
}

interface LobbyComponentState {
    userName: string;
    isMobile: boolean;
}

type Props = InputProps & DispatchProps & FromStateProps;

const MAX_PARTICIPANTS = 10;

const Participants: React.SFC<{amount: number, conferenceMembers: Array<any>}> = ({amount, conferenceMembers}) => {
    const conferenceMemberSize = conferenceMembers && conferenceMembers.length;
    const totalParticipants = conferenceMemberSize || amount || 0;

    if (totalParticipants === 0) {
        return <div>No participants</div>;
    } else {
        return <div>{`${totalParticipants} participant${totalParticipants > 1 ? 's' : ''}`}</div>;
    }
};

const isChrome = (window as any).chrome && (window as any).chrome.runtime;

/*
const installExtension = () => {
    const chrome = (window as any).chrome;
    chrome.webstore.install("https://chrome.google.com/webstore/detail/hajcgcfghpaioaicinacgidilhgebglj", () => console.log("Inline extension install successful"), (e: any) => console.log("Error installing extension", e));
}
*/

const ExtensionDownload: React.SFC<{}> = () => {
    if (isChrome) {
        const url = "https://chrome.google.com/webstore/detail/flow-screen-sharing/hajcgcfghpaioaicinacgidilhgebglj";
        /*
        const chrome = (window as any).chrome;

        if (chrome.app.isInstalled) {
            console.log("Extension is installed!");
        }

        <button style={{ display: "hidden" }} onClick={installExtension}>Install</button>
        */
        return (
            <div className="extension-download">
                If you would like to share your screen, install our <a href={url} target="_blank">Chrome Extension</a>.
            </div>
        );
    } else {
        return null;
    }
};

const SupportedBrowsers: React.SFC<{}> = () => {
    const supported = isChrome || (navigator.mediaDevices.getSupportedConstraints() as any)!["mediaSource"];

    if (isMobileOrTablet() || !supported) {
        return (
            <div style={{ textAlign: 'center', padding: 12 }}>
                For the best experience, use the latest version of Chrome on a desktop device.
            </div>
        );
    } else {
        return null;
    }
};

class Lobby extends React.Component<Props, LobbyComponentState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            userName: !!props.userName ? props.userName : "",
            isMobile: isMobileOrTablet(),
        };
    }

    public render() {
        return (
            <div className="lobbywrapper">
                <div className={'top-icon-holder'}>
                    <BetaInfo beta={this.props.beta}/>
                </div>

                <div className={`lobby ${this.state.isMobile ? 'mobile' : ''}`}>
                    <div className="name">Join Video Conference</div>
                    <div className="participants">
                        <Participants amount={this.props.participants} conferenceMembers={this.props.conferenceMembers} />
                    </div>
                    <input
                      type="text"
                      onChange={this.updateUserName}
                      onKeyUp={this.maybeSubmit}
                      value={this.state.userName}
                      placeholder={"Enter your name"}
                      autoFocus={true} />
                    <button
                        onClick={this.joinRoom}
                        disabled={this.state.userName.length === 0 || (this.props.participants >= MAX_PARTICIPANTS && !this.props.beta)}>
                        JOIN VIDEO CONFERENCE
                    </button>
                    {this.props.participants >= MAX_PARTICIPANTS && !this.props.beta ? (<div style={{ padding: 12 }}>The room is currently at max capacity</div>) : null}
                    {this.props.error ? <div className="error">{this.props.error}</div> : null}

                    <SupportedBrowsers />
                    <ExtensionDownload />
                </div>
            </div>
        );
    }

    public componentDidMount() {
        this.props.enterLobby(this.props.roomName);
    }

    private enterRoom = () => {
        if (this.state.userName.length > 0) {
            // Username begins after 37 char, scuffed af but due to backend legacy using input names as keys
            // Appending uuids to names as quick dirty fix
            const uuid = uuidv4();
            const uniqueUserName = `${uuid}-${this.state.userName}`;
            this.props.joinRoom(this.props.roomName, uniqueUserName);
        }
    };

    private maybeSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.keyCode === 13 && (this.props.participants < MAX_PARTICIPANTS || this.props.beta)) {
            this.enterRoom();
        }
    };

    private updateUserName = (e: React.SyntheticEvent<HTMLInputElement>) => {
        e.preventDefault();

        this.setState({
            userName: e.currentTarget.value
        });
    };

    private joinRoom = (e: React.SyntheticEvent<HTMLButtonElement>) => {
        e.preventDefault();

        this.enterRoom();
    };
}

const mapStateToProps = (state: RootState): FromStateProps => {
    const {room: {error, lobbyParticipants, conferenceMembers}} = state;

    return {
        error: error,
        participants: lobbyParticipants,
        conferenceMembers
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, {}, any>): DispatchProps => ({
    joinRoom: (room: string, name: string) =>
        dispatch(Room.Actions.joinRoom(room, name)),

    enterLobby: (roomName) =>
        dispatch(Room.Actions.enterLobby(roomName))
});

const ConnectedLobby = connect(
    mapStateToProps,
    mapDispatchToProps
)(Lobby);

export default ConnectedLobby;
