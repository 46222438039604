import * as React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
    isOpen: boolean,
    title: string,
    bodyText: string,
    affirmativeText: string,
    handleAffirmative: () => void,
    negativeText?: string,
    handleNegative?: () => void
}

export const AlertDialog = ({isOpen, title, bodyText, affirmativeText, handleAffirmative, negativeText, handleNegative} : Props) => {

    const handleOnClose = handleNegative || handleAffirmative;

    return (
        <div>
            <Dialog
              open={isOpen}
              onClose={handleOnClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {bodyText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {handleNegative ?
                        <Button onClick={handleNegative} style={{font: '11px Gotham Medium'}}>
                            {negativeText}
                        </Button> : null
                    }
                    <Button onClick={handleAffirmative} style={{font: '11px Gotham Medium', color: '#1a97fc'}}>
                        {affirmativeText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};