/**
 * This module exports a singleton that stores the js bundle filename
 */

class AirbrakeClientParams {
    bundleFilename = '';

    setBundleFilename = (filename) => {
        this.bundleFilename = filename;
    };

    getBundleFilename = () => {
        return this.bundleFilename;
    };
}

const airbrakeClientParams = new AirbrakeClientParams();

export default airbrakeClientParams;
