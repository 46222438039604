const HOME_API_URL = 'https://home.telavox.se/api/external/conference';

export const DEFAULT_SIP_PROXIES = [
    'wss://sipproxy5.telavox.se:443',
    'wss://sipproxy6.telavox.se:443',
    'wss://sipproxy7.telavox.se:443',
];

interface ApiEndpoint {
    endpoint?: string;
    meetingCode: string
}

// Numbers from https://www.joinflow.com/en/conference/
export const COUNTRY_NUMBER_LIST = [
    { country: 'Argentina', number: '+54 11 5252 6755'},
    { country: 'Australia', number: '+61 261 452 007'},
    { country: 'Austria', number: '+43 720 88 22 19'},
    { country: 'Belgium', number: '+32 2 808 57 01'},
    { country: 'Brazil', number: '+55 61 3550'},
    { country: 'Bulgaria', number: '+359 2 491 6113'},
    { country: 'Canada', number: '+1 780 800 4820'},
    { country: 'Chile', number: '+56 22 570 8658'},
    { country: 'Cyprus', number: '+357 22 232 371'},
    { country: 'Czechia', number: '+420 2 2888 0810'},
    { country: 'Denmark', number: '+45 89 88 06 34'},
    { country: 'Dominican Republic', number: '+1829 956 6919'},
    { country: 'El Salvador', number: '+503 2 113 3878'},
    { country: 'Estonia', number: '+372 66 83157'},
    { country: 'Finland', number: '+358 93158 4603'},
    { country: 'France', number: '+33 9 75 18 37 92'},
    { country: 'Germany', number: '+49 911 374 90 69'},
    { country: 'Greece', number: '+30 21 1198 1244'},
    { country: 'Israel', number: '+972 3763 0691'},
    { country: 'Italy', number: '+39 06 9480 1027'},
    { country: 'Japan', number: '+81 3 4578 1782'},
    { country: 'Latvia', number: '+371 6616 3369'},
    { country: 'Lithuania', number: '+370 5 2140 385'},
    { country: 'Luxembourg', number: '+352 2786 0285'},
    { country: 'Malta', number: '+356 2778 0693'},
    { country: 'Mexico', number: '+52 554 170 3796'},
    { country: 'New Zealand', number: '+64 4 974 7660'},
    { country: 'Norway', number: '+47 23 96 02 46'},
    { country: 'Panama', number: '+507 833 9150'},
    { country: 'Peru', number: '+51 1 707 43 23'},
    { country: 'Poland', number: '+48 22 397 8843'},
    { country: 'Portugal', number: '+351 308 80 32'},
    { country: 'Puerto Rico', number: '+1787 777 1473'},
    { country: 'Romania', number: '+40 31 630 0302'},
    { country: 'Slovakia', number: '+421 2 3305 7959'},
    { country: 'Slovenia', number: '+386 1 888 8418'},
    { country: 'South Africa', number: '+27 875 502 185'},
    { country: 'Spain', number: '+34 91 123 4690'},
    { country: 'Sweden', number: '+46 770 33 99 88'},
    { country: 'Switzerland', number: '+41 31 528 0310'},
    { country: 'USA', number: '+1 202 599 9486'},
    { country: 'United Kingdom', number: '+44 20 3608 1957'}
];

export const fetchConferenceDataFromEndpoint = async (endpointData: ApiEndpoint) => {
    const { endpoint, meetingCode } = endpointData;
    let result = [];
    try {
        if (meetingCode) {
            const fetchUrl = `${HOME_API_URL}/${meetingCode}${endpoint ? '/' + endpoint : ''}`;
            const res = await fetch(fetchUrl);
            result = await res.json();
        }
    } catch (e) {
        console.error(`Error fetching members for meetingcode ${meetingCode}, endpoint ${endpoint}:`, e);
    }
    return result
};
