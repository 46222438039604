export {GroupCallClient};

interface KMSMessage {
    id: string;
}

interface ExistingParticipants extends KMSMessage {
    data: Array<string>;
}

interface NewParticipantArrived extends KMSMessage {
    name: string;
}

interface ParticipantLeft extends KMSMessage {
    name: string;
}

interface VideoAnswer extends KMSMessage {
    name:      string;
    sdpAnswer: string;
}

interface RestartVideoFrom extends KMSMessage {
    name: string;
}

interface IceMessage extends KMSMessage {
    name:      string;
    candidate: RTCIceCandidateInit;
}

interface RoomJoined extends KMSMessage {
    room: string;
    name: string;
}

interface RoomNotJoined extends KMSMessage {
    room:   string;
    reason: string;
}

interface LobbyInfoMessage extends KMSMessage {
    room:         string;
    participants: number;
}

interface TrackDisabledMessage extends KMSMessage {
    name:   string;
    kind:   'audio' | 'video';
}

interface TrackEnabledMessage extends KMSMessage {
    name:   string;
    kind:   'audio' | 'video';
}

interface MediaCapabilitiesMessage extends KMSMessage {
    name: string;
    audioEnabled: boolean;
    videoEnabled: boolean;
}

interface PingMessage extends KMSMessage {
    uuid: string;
}

export type SocketState = 'connecting' | 'open' | 'closing' | 'closed';

interface GroupCallCallbacks {
    updateSocketState(state: SocketState, reason?: string, code?: number): void;
    onExistingParticipants(participantNames: Array<string>): void;
    onIceCandidate(name: string, candidate: RTCIceCandidate): void;
    onNewParticipant(participantName: string): void;
    onParticipantLeft(participant: string): void;
    onReceiveVideoAnswer(name: string, sdpAnswer: string): void;
    onRestartVideoFrom(name: string): void;
    onRoomNotJoined(room: string, reason: string): void;
    onRoomJoined(room: string, name: string): void;
    onLobbyInfo(room: string, participants: number): void;
    onTrackDisabled(name: string, kind: 'audio' | 'video'): void;
    onTrackEnabled(name: string, kind: 'audio' | 'video'): void;
    onError(reason: string): void;
}

/**
 * The interface DebugWindow is a trick to get around typescript warnings. The
 * purpose is that one can define and use new variables on the window object in
 * the browser console.
 */
export interface DebugWindow extends Window {
    debugICE: boolean;
}

declare let window: DebugWindow;

class GroupCallClient {
    private ws: WebSocket;
    private cb: GroupCallCallbacks;
    private canSendJoin: boolean = true;

    constructor(ws_url: string) {
        this.ws = new WebSocket(ws_url);

        this.cb = {
            updateSocketState: (state: SocketState, reason?: string, code?: number) => console.debug('updateSocketState', state, reason, code),
            onExistingParticipants: (participants) => console.debug("onExistingParticipants", participants),
            onIceCandidate: (name, candidate) => console.debug("onIceCandidate", name, candidate),
            onNewParticipant: (participant) => console.debug("onNewParticipant", participant),
            onParticipantLeft: (participant) => console.debug("onParticipantLeft", participant),
            onReceiveVideoAnswer: (name, sdpAnswer) => console.debug("onReceiveVideoAnswer", name, sdpAnswer),
            onRestartVideoFrom: (name) => console.debug("onRestartVideoFrom", name),
            onRoomNotJoined: (room, reason) => console.debug("onRoomIsFull", room, reason),
            onRoomJoined: (room, name) => console.debug("onRoomJoined", room, name),
            onLobbyInfo: (room, participants) => console.debug("onLobbyInfo", room, participants),
            onTrackDisabled: (name, kind) => console.debug('onTrackDisabled', name, kind),
            onTrackEnabled: (name, kind) => console.debug('onTrackEnabled', name, kind),
            onError: (reason) => console.debug(`onError: '${reason}'`)
        }

        this.ws.onopen = () => {
            this.ws.send(JSON.stringify({ id: "hello" }));
            this.cb.updateSocketState('open');
        };

        this.ws.onmessage = (message) => {
            this.handleMessage(JSON.parse(message.data));
        };

        this.ws.onerror = (_error: Event) => {
            this.cb.updateSocketState('closed');
            this.cb.onError(`Connection error`);
        };

        this.ws.onclose = (ev: CloseEvent) => {
            this.cb.updateSocketState('closed', ev.reason, ev.code);
            const code: number = ev.code;
            const reason: string = ev.reason || '-';
            const clean: string = ev.wasClean ? 'not' : '';
            console.info(`Groupcall socket closed [code: ${code}, reason: ${reason}]. It was ${clean} clean.`);
        };
    }

    public close = () => {
        this.cb.updateSocketState('closing');
        this.ws.close();
    };

    public setCallbacks = (cb: GroupCallCallbacks) =>
        this.cb = cb;

    private handleMessage = (msg: KMSMessage) => {
        //console.log("Handling incoming", msg);

        switch (msg.id) {
            case "existingParticipants": {
                this.cb.onExistingParticipants((msg as ExistingParticipants).data);
                break;
            }

            case "newParticipantArrived": {
                this.cb.onNewParticipant((msg as NewParticipantArrived).name);
                break;
            }

            case "participantLeft": {
                this.cb.onParticipantLeft((msg as ParticipantLeft).name);
                break;
            }

            case "receiveVideoAnswer": {
                const parsed = msg as VideoAnswer;
                this.cb.onReceiveVideoAnswer(parsed.name, parsed.sdpAnswer);
                break;
            }

            case "restartVideoFrom": {
                this.cb.onRestartVideoFrom((msg as RestartVideoFrom).name);
                break;
            }

            case "iceCandidate": {
                const parsed = msg as IceMessage;
                const candidate = new RTCIceCandidate(parsed.candidate);
                if (window.debugICE) {
                    console.info(`Received ICE candidate "${candidate.candidate}"`);
                }
                this.cb.onIceCandidate(parsed.name, candidate);
                break;
            }

            case "roomNotJoined": {
                this.canSendJoin = true;
                const parsed = msg as RoomNotJoined;
                this.cb.onRoomNotJoined(parsed.room, parsed.reason);
                break;
            }

            case "roomJoined": {
                this.canSendJoin = true;
                const parsed = msg as RoomJoined;
                this.cb.onRoomJoined(parsed.room, parsed.name);
                break;
            }

            case "lobby": {
                const parsed = msg as LobbyInfoMessage;
                this.cb.onLobbyInfo(parsed.room, parsed.participants);
                break;
            }

            case "trackDisabled": {
                const parsed = msg as TrackDisabledMessage;
                this.cb.onTrackDisabled(parsed.name, parsed.kind);
                break;
            }

            case "trackEnabled": {
                const parsed = msg as TrackEnabledMessage;
                this.cb.onTrackEnabled(parsed.name, parsed.kind);
                break;
            }

            case "mediaCapabilitiesFrom": {
                const parsed = msg as MediaCapabilitiesMessage;
                console.info(
                    `Received '${parsed.id}' from '${parsed.name}' with 'audioEnabled'='${parsed.audioEnabled}' and 'videoEnabled'='${parsed.videoEnabled}'`
                );
                break;
            }

            case "ping": {
                console.debug("Received 'pong' message");
                const parsed = msg as PingMessage;
                this.send({
                    id: "pong",
                    uuid: parsed.uuid
                });
                break;
            }
        }
    };

    public enterLobby = (roomName: string): void =>
        this.send({
            id: "enterLobby",
            room: roomName
        });

    public joinRoom = (roomName: string, userName: string): void => {
        if (this.canSendJoin) {
            this.send({
                id: "joinRoom",
                room: roomName,
                name: userName
            });
            this.canSendJoin = false;
        } else {
            console.warn("'joinRoom' called without a response being received");
        }
    };

    public leaveRoom = () =>
        this.send({
            id: "leaveRoom"
        });

    public sendIceCandidate = (name: string, candidate: RTCIceCandidate) => {
        if (window.debugICE) {
            console.info(`Sending ICE candidate "${candidate.candidate}"`);
        }
        if (!candidate.candidate) {
            console.warn(`Empty candidate in ${JSON.stringify(candidate)}. Not sending to host.`);
            return;
        }
        this.send({
            id: "onIceCandidate",
            name: name,
            candidate: candidate
        });
    };

    public sendOfferToReceiveVideo = (name: string, sdpOffer: string) =>
        this.send({
            id: "receiveVideoFrom",
            sender: name,
            sdpOffer: sdpOffer
        });

    public sendCancelVideoFrom = (sender: string) =>
        this.send({
            id: "cancelVideoFrom",
            sender: sender
        });

    public sendRestartVideo = (sender: string) =>
        this.send({
            id: "restartVideo",
            sender: sender
        });

    public sendTrackDisabled = (kind: string) => {
        this.send({
            id: "trackDisabled",
            kind: kind
        })
    };

    public sendTrackEnabled = (kind: string) => {
        this.send({
            id: "trackEnabled",
            kind: kind
        })
    };

    public sendMediaCapabilities = (audioEnabled: boolean, videoEnabled: boolean) => {
        this.send({
            id: "mediaCapabilities",
            audioEnabled: audioEnabled,
            videoEnabled: videoEnabled
        });
    };

    private send = (obj: object) =>
        this.ws.send(JSON.stringify(obj));
}
