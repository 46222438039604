import * as Redux from 'redux';
import thunk from 'redux-thunk';

import * as App from 'state/app';
import * as Client from 'state/client';
import * as Room from 'state/room';

import appConfig from 'app/app-config';

export { store, RootState };

interface RootState {
    room: Room.State;
    remote: Client.State;
    app: App.State;
}

const rootReducer = Redux.combineReducers<RootState>({
    room:   Room.reducer,
    remote: Client.reducer,
    app:    App.reducer
});

const configureStore = () => {
    if (appConfig.production) {
        return Redux.createStore(rootReducer, Redux.applyMiddleware(thunk));
    }
    // @ts-ignore
    return Redux.createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), Redux.applyMiddleware(thunk));
};

const store = configureStore();
