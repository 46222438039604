import * as React from "react";

const HangUpIcon: React.SFC<{}> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M12 9c-1.6 0-3.15.25-4.6.72v3.1c0 .39-.23.74-.56.9-.98.49-1.87 1.12-2.66 1.85-.18.18-.43.28-.7.28-.28 0-.53-.11-.71-.29L.29 13.08c-.18-.17-.29-.42-.29-.7 0-.28.11-.53.29-.71C3.34 8.78 7.46 7 12 7s8.66 1.78 11.71 4.67c.18.18.29.43.29.71 0 .28-.11.53-.29.71l-2.48 2.48c-.18.18-.43.29-.71.29-.27 0-.52-.11-.7-.28-.79-.74-1.69-1.36-2.67-1.85-.33-.16-.56-.5-.56-.9v-3.1C15.15 9.25 13.6 9 12 9z"/>
        </svg>
    );
};

const VideoMuteIcon: React.SFC<{}> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0zm0 0h24v24H0z" fill="none"/>
            <path d="M21 6.5l-4 4V7c0-.55-.45-1-1-1H9.82L21 17.18V6.5zM3.27 2L2 3.27 4.73 6H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.21 0 .39-.08.54-.18L19.73 21 21 19.73 3.27 2z"/>
        </svg>

    );
};

const AudioMuteIcon: React.SFC<{}> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0zm0 0h24v24H0z" fill="none"/>
            <path d="M19 11h-1.7c0 .74-.16 1.43-.43 2.05l1.23 1.23c.56-.98.9-2.09.9-3.28zm-4.02.17c0-.06.02-.11.02-.17V5c0-1.66-1.34-3-3-3S9 3.34 9 5v.18l5.98 5.99zM4.27 3L3 4.27l6.01 6.01V11c0 1.66 1.33 3 2.99 3 .22 0 .44-.03.65-.08l1.66 1.66c-.71.33-1.5.52-2.31.52-2.76 0-5.3-2.1-5.3-5.1H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c.91-.13 1.77-.45 2.54-.9L19.73 21 21 19.73 4.27 3z"/>
        </svg>

    );
};

const ScreenIcon: React.SFC<{}> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0V0z"/>
            <path d="M20 18c1.1 0 1.99-.9 1.99-2L22 6c0-1.11-.9-2-2-2H4c-1.11 0-2 .89-2 2v10c0 1.1.89 2 2 2H0v2h24v-2h-4zm-7-3.53v-2.19c-2.78 0-4.61.85-6 2.72.56-2.67 2.11-5.33 6-5.87V7l4 3.73-4 3.74z"/>
        </svg>
    );
};

interface Props {
    type:       string,
    onClick:    (e: React.SyntheticEvent<HTMLElement>) => void,
    toggled?:   boolean
}

export const ControlPanelButton = ({type, onClick, toggled} : Props) => {

    const icon = getControlPanelButtonIcon(type);
    const text = getControlPanelButtonText(type);

    let className;
    if (type === 'HANGUP') {
        className = "exit-button";
    } else {
        const classNameSuffix = toggled ? 'active' : 'not-active';
        className = `activation-button ${classNameSuffix}`;
    }

    return (
        <div className={'control-panel-button'}>
            <button className={className} onClick={onClick}>
                {icon}
            </button>
            {text}
        </div>
    );
};

const getControlPanelButtonIcon = (type: string) => {
    switch(type) {
        case 'HANGUP':
            return <HangUpIcon/>;
        case 'MUTE_AUDIO':
            return <AudioMuteIcon/>;
        case 'MUTE_VIDEO':
            return <VideoMuteIcon/>;
        case 'SHARE_SCREEN':
            return <ScreenIcon/>;
        default:
            return null;
    }
};

const getControlPanelButtonText = (type: string) => {
    switch(type) {
        case 'HANGUP':
            return 'Hang up';
        case 'MUTE_AUDIO':
            return 'Mute';
        case 'MUTE_VIDEO':
            return 'Camera off';
        case 'SHARE_SCREEN':
            return 'Share screen';
        default:
            return '';
    }
};
