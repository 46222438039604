import * as React from 'react';
import * as ReactRedux from 'react-redux';

import * as App from 'state/app';
import { RootState } from 'state/store';
import { ControlPanelButton } from 'app/component/control-panel-button';
import LocalSession from 'app/component/local-session';
import { CSSProperties } from 'react';
import { isMobileOrTablet } from '../utils/app-utils';

export { ControlPanel };

type CameraSource = "camera"
type ScreenSource = "screen" | "window" | "application" | "desktop";
type Source = CameraSource | ScreenSource;

interface InputProps {
    selectScreenDevice?:            (source: ScreenSource) => void;
    selectCameraDevice:             () => void;
    toggleAudioMute:                () => void;
    toggleVideoMute:                () => void;
    toggleCamera:                   () => void;
    leaveRoom:                      () => void;
    displayChromeExtensionDialog:   () => void;

    audioMuted:                     boolean;
    videoMuted:                     boolean;
    activeSource:                   Source;
    chromeExtReqForScreenSharing:   boolean;
    ownVideoMinimized: boolean;
    toggleOwnStreamCallback: () => void;
    fullScreen: boolean;
    toggleShowJoinInfo: () => void;
}

interface FromStateProps {
    app: App.State
}

interface ComponentState {
    extraDeviceSelection: "show" | "hide";
    isMobile: boolean;
}

type Props = InputProps & FromStateProps;

const ExtraDeviceSelection: React.SFC<{onSelect: (source: ScreenSource) => void}> = ({onSelect}) => {
    return (
        <div>
            <button onClick={() => onSelect("screen")}>Screen</button>
            <button onClick={() => onSelect("window")}>Window</button>
            <button onClick={() => onSelect("application")}>Application</button>
        </div>
    );
};

class ControlPanelComponent extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            extraDeviceSelection: "hide",
            isMobile: isMobileOrTablet(),
        };
    }

    public render() {
        const { alpha } = this.props.app
        const deviceSelection = !!this.props.selectScreenDevice;
        const cameraActive = this.props.activeSource === "camera";

        const displayShareScreenButton = deviceSelection || this.props.chromeExtReqForScreenSharing;
        const onShareScreenButtonClicked = this.props.chromeExtReqForScreenSharing ?
            this.props.displayChromeExtensionDialog :
            this.setScreenDevice;

        const ownVideoCSS : CSSProperties = this.props.ownVideoMinimized ? {
            position: 'absolute',
            right:12,
            bottom: this.state.isMobile ? 84 : ''
        } : {
            position: 'absolute',
            right:0,
            padding: 12,
            bottom: 56
        };

        return (
            <div className="control-panel">
                {alpha ? (
                    <div className={'joining-info'} onClick={this.props.toggleShowJoinInfo} >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg>
                        {this.state.isMobile ? null : (
                            <div style={{ paddingLeft: 9 }}>
                                Joining info
                            </div>
                        )}
                    </div>
                ) :
                    null
                }
                <div className="button-container">
                    <ControlPanelButton type={"HANGUP"} onClick={this.leaveRoom}/>
                    <ControlPanelButton type={"MUTE_AUDIO"} onClick={this.props.toggleAudioMute} toggled={this.props.audioMuted}/>
                    <ControlPanelButton type={"MUTE_VIDEO"} onClick={this.props.toggleVideoMute} toggled={this.props.videoMuted}/>
                    {displayShareScreenButton && <ControlPanelButton type={"SHARE_SCREEN"} onClick={onShareScreenButtonClicked} toggled={!cameraActive}/>}
                    {this.state.extraDeviceSelection === "show" ? <ExtraDeviceSelection onSelect={this.activateScreenDevice} /> : null}
                </div>
                <div style={ownVideoCSS} onClick={!this.props.fullScreen ? this.props.toggleOwnStreamCallback : undefined}>
                    <LocalSession
                        hideLocalSession={false}
                        ownVideoMinimized={this.props.ownVideoMinimized}
                        fullScreen={this.props.fullScreen}
                    />
                </div>
            </div>
        );
    }

    private activateScreenDevice = (source: ScreenSource) => {
        this.setState({
            extraDeviceSelection: "hide"
        });

        this.props.selectScreenDevice!(source);
    };

    private setScreenDevice = () => {
        if (this.props.activeSource === "camera") {
            if (this.props.app.mediaSourceSupport) {
                this.setState({
                    extraDeviceSelection: "show"
                });
            } else {
                this.props.selectScreenDevice!("desktop");
            }
        } else {
            this.props.selectCameraDevice();
        }
    };

    private leaveRoom = (e: React.SyntheticEvent<HTMLElement>) => {
        e.preventDefault();

        this.props.leaveRoom();
    };
}

const mapStateToProps = (state: RootState): FromStateProps => {
    return {
        app: state.app
    };
};

const ControlPanel = ReactRedux.connect(
    mapStateToProps
)(ControlPanelComponent);
