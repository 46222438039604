import * as Analytics from 'analytics-node';
import * as uuidv4 from 'uuid/v4';
import { getCookieAccountId } from 'app/utils/cookie-utils';

import appConfig from 'app/app-config';

class SegmentClient {
    constructor() {
        if (appConfig.production) {
            this.analytics = new Analytics('GWcHKRi95XufxjRm6PaVxwgNfvX9ydxp');
        } else {
            this.analytics = {
                identify: (data) => {
                    console.info("Segment [identify]:", JSON.stringify(data));
                },
                track: (trackingData) => {
                    console.info("Segment [track]:", JSON.stringify(trackingData));
                }
            }
        }

        this.anonymousId = uuidv4();
        this.initialized = true;
    }
    track(event, properties) {
        if (!this.initialized) {
            return;
        }

        /**
         * The following two segments are done in the track method as the accountId
         * could have been set after the constructor initialized, not linking the
         * accountId to the segment analytics link.
         */
        if (!this.accountId && !this.checkedCookie) {
            this.accountId = getCookieAccountId();
            this.checkedCookie = true; // Check only once
        }
        if (!this.setupIdentifyDone) {
            this.analytics.identify({
                anonymousId: this.anonymousId,
                userId: this.accountId ? `extension-${this.accountId}` : undefined
            });
            this.setupIdentifyDone = true;
        }

        const trackingObject = {
            anonymousId: this.anonymousId,
            event: event,
            userId: this.accountId ? `extension-${this.accountId}` : undefined
        };
        if (properties) {
            trackingObject.properties = properties;
        }
        this.analytics.track(trackingObject);
    }
}

const segmentClient = new SegmentClient();
export default segmentClient;
