import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { GroupCallClient, SocketState } from 'groupcall-client';
import segmentClient from 'clients/segment-client';
import * as App from 'state/app';
import * as Client from 'state/client';
import * as Room from 'state/room';
import { RootState } from 'state/store';
import Lobby from 'app/component/lobby';
import { Room as RoomComponent } from 'app/component/room';

import { fetchConferenceDataFromEndpoint } from 'app/utils/conference-utils';
import { ParamData, parseGetParams, createHostUrl } from 'app/utils/parsing-utils';
import {setCookieData, getCookieUsername, getCookieBeta} from 'app/utils/cookie-utils';
import { EVENT_STRINGS } from 'app/utils/segment-utils';
// @ts-ignore
import * as uuidv4 from 'uuid/v4';
// @ts-ignore
import { SHA256 } from 'crypto-js';
import Switch from '@material-ui/core/Switch';

export { Main };

interface FromStateProps {
    socketState: SocketState;
    room:      Room.State;
    client:    GroupCallClient;
    status?:   string;
    micPermission: App.PermissionState;
    camPermission: App.PermissionState;
}

interface FromDispatchProps {
    registerClient:          (client: GroupCallClient) => void;
    unregisterClient:        () => void;
    activateChromeExtension: () => void;
    autoJoinRoom:            (room: string, user: string) => void;
    setAlpha: () => void;
    setMeetingCode: (meetingCode: string) => void;
    setConferenceData: (pin: string, conferenceMembers: Array<any>) => void;
}

interface MainComponentState {
    redirectBeta: boolean;
}

type Props = FromStateProps & FromDispatchProps;

const EnterLobby: React.SFC<{status?: string}> = ({status}) => {
    const text = status ? 'RETRY' : 'ENTER LOBBY';

    return (
        <div className="enterLobby">
            <div className="lobby">
                <div className="flow-logo"/>
                <div className="name">Video Conference</div>
                {status && <div className="error">{status}</div>}
                <button onClick={() => location.reload()}>{text}</button>
            </div>
        </div>
    );
};

const Connecting: React.SFC = () => {
    return <div className='socket-connecting'>Connecting...</div>;
};

class MainComponent extends React.Component<Props, MainComponentState> {

    private roomName?: string;
    private userName?: string;
    private autoJoin: boolean;
    private wsUrl: string;
    private beta?: string;
    private alpha?: boolean;
    private interval: any;
    private settings?: boolean;

    constructor(props: Props) {
        super(props);

        this.state = {
            redirectBeta: false,
        };

        const params = window.location.search.substring(1).split(/&/g);
        if (params.length === 1 && params[0] === '') {
            this.settings = true;
            this.autoJoin = false;
            const urlParams : ParamData = parseGetParams();
            this.wsUrl = createHostUrl(urlParams.kmsServer, urlParams.port);
            return;
        }

        const urlParams : ParamData = parseGetParams();
        // Check if the room name is legit
        if (urlParams.roomName && /^[0-9a-fA-F]{64}$/.test(urlParams.roomName)) {
            this.roomName = urlParams.roomName;
        } else if (urlParams.roomName && /^[0-9a-fA-F.\\-]{36}$/.test(urlParams.roomName)) {
            /*
            * The room name is a UUID, used to create a call.
            * We should update the alpha flag to another name
            * but oh well here we go.
            * */

            // Yeah no backend fix, just brute force the UUID to SHA256
            this.roomName = SHA256(urlParams.roomName).toString();
            this.alpha = true;

            // Inform the store
            this.props.setAlpha();
            this.props.setMeetingCode(urlParams.roomName);

        } else {
            console.warn(`Invalid room name: ${urlParams.roomName}`);
            this.roomName = undefined;
        }

        this.userName = urlParams.userName || getCookieUsername() || undefined;
        this.autoJoin = urlParams.autoJoin === '1';
        this.beta = urlParams.beta || getCookieBeta();

        setCookieData(urlParams);

        const urlRoomName = this.alpha ? urlParams.roomName : this.roomName;
        this.replaceUri(urlRoomName || '', urlParams.beta, urlParams.alpha, urlParams.useTvxUrl === '1');

        this.wsUrl = createHostUrl(urlParams.kmsServer, urlParams.port);
    }

    private fetchConferenceData = async () => {
        const urlParams : ParamData = parseGetParams();
        const meetingCode = urlParams.roomName;
        const data = await fetchConferenceDataFromEndpoint({ meetingCode });
        const pin = data.pin;
        const conferenceMembers = data.conferenceMembers;
        this.props.setConferenceData(pin, conferenceMembers);
    };

    private setNewMeetingCode = () => new Promise(async (resolve, reject) => {
        const urlParams : ParamData = parseGetParams();
        let meetingCode;
        try {
            const fetchUrl = `https://home.telavox.se/api/external/conference/convert/${urlParams.roomName}`;
            const res = await fetch(fetchUrl);
            const data = await res.json();
            meetingCode = data.meetingCode;
        } catch (e) {
            console.error('Error fetching meetingcode, ', e);
            reject();
        }
        if (meetingCode) {
            this.replaceUri(meetingCode, urlParams.beta, urlParams.alpha);
            this.props.setAlpha();
            this.props.setMeetingCode(meetingCode);
            this.roomName = SHA256(meetingCode).toString();
            resolve();
        }
        reject();
    });

    public async componentDidMount() {
        const urlParams : ParamData = parseGetParams();

        // If SHA256 -> Get meetingcode and use it.
        if (/^[0-9a-fA-F]{64}$/.test(urlParams.roomName)) {
            await this.setNewMeetingCode();
        }

        const tvxBetaUrl = urlParams.useTvxUrl === '1' ? 'https://flowapp.telavox.com/video?room=' : 'https://app.joinflow.com/video?room=';
        const uuidCheck = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-5][0-9a-fA-F]{3}-[089abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
        let roomName;
        if (uuidCheck.test(urlParams.roomName)) {
            roomName = urlParams.roomName;
        } else if (uuidCheck.test(this.props.room.meetingCode)) {
            roomName = this.props.room.meetingCode;
        }
        if (roomName) {
            window.location.href = `${tvxBetaUrl}${roomName}`;
        } else {
            console.error('Trying to redirect but room name was not a UUID.');
        }

        if (urlParams.roomName && /^[0-9a-fA-F.\\-]{36}$/.test(urlParams.roomName)) {
            await this.fetchConferenceData();
            const interval = setInterval(this.fetchConferenceData, 10000);
            this.interval = interval;
        }

        const client = new GroupCallClient(this.wsUrl);
        this.props.registerClient(client);
        this.props.activateChromeExtension();

        segmentClient.track(EVENT_STRINGS.ENTERED_LOBBY, {
            roomId: this.roomName ? this.roomName : ''
        });
    }

    private replaceUri = (roomName: string, beta?: string, alpha?: string, useTvxUrl?: boolean) => {
        window.history.replaceState({}, document.title,
            '/'
            + (this.roomName ? `?room=${roomName}` : '')
            + (beta ? `&beta=${beta}` : '')
            + (alpha ? `&alpha=${alpha}` : '')
            + (useTvxUrl ? `&t=1` : '')
        );
    };

    public componentWillUnmount() {
        clearInterval(this.interval);
    }

    public componentDidUpdate(prevProps: Props) {
        const { socketState } = this.props;

        // Wait to auto-join until socket is opened
        if (socketState === 'open' && prevProps.socketState === 'connecting') {
            if (this.autoJoin && this.roomName && this.userName) {
                // Username begins after 37 char, scuffed af but due to backend legacy using input names as keys
                // Appending uuids to names as quick dirty fix
                const uuid = uuidv4();
                const uniqueUserName = `${uuid}-${this.userName}`;
                this.props.autoJoinRoom(this.roomName!, uniqueUserName!);
            }
        }
    }

    private changeUseBetaChange = () => {
        const newVal = !this.state.redirectBeta;
        this.setState({ redirectBeta: newVal });
        setCookieData({useTvxBeta: `${newVal}` });
    };

    public render() {
        if (this.settings) {
            return <div className="flow-dialog" style={{ background: '#313131' }}>
                <div style={{ paddingBottom: 12 }}>{'Settings'}</div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '0 24px', }}>
                    <div>{'Enable beta conference'}</div>
                    <div>
                        <Switch color="default" checked={this.state.redirectBeta} onChange={this.changeUseBetaChange}/>
                    </div>
                </div>
            </div>;
        }
        if (!this.roomName) {
            return <div className="flow-dialog">{`Not a valid room name`}</div>;
        }
        if (this.props.socketState === 'connecting') {
            return <Connecting />;
        } else if (this.props.socketState === 'closed') {
            return <EnterLobby status={this.props.status} />;
        }
        if (this.props.room.state === "room") {
            return <RoomComponent beta={this.beta} />;
        } else if (this.props.room.state === "lobby") {
            return <Lobby roomName={this.roomName} userName={this.userName} beta={this.beta} />;
        } else {
            return <div className="error">{`Unknown state: ${this.props.room.state}`}</div>;
        }
    }
}

const mapStateToProps = (state: RootState): FromStateProps => {
    const {
        remote: {
            client,
            status,
            socketState
        },
        room,
        app: {
            micPermission,
            camPermission,
        }
    } = state;

    return {
        socketState,
        client,
        room,
        status,
        micPermission,
        camPermission,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, {}, any>): FromDispatchProps => {
    return {
        registerClient: (client: GroupCallClient) =>
            dispatch(Client.Actions.register(client)),

        unregisterClient: () =>
            dispatch(Client.Actions.unregister()),

        activateChromeExtension: () =>
            dispatch(App.Actions.activateChromeExtension()),

        autoJoinRoom: (room, user) =>
            dispatch(Room.Actions.joinRoom(room, user)),

        setAlpha: () => dispatch(App.Actions.enableAlpha()),

        setMeetingCode: (meetingCode: string) => dispatch(Room.Actions.setMeetingCode(meetingCode)),

        setConferenceData: (pin: string, conferenceMembers: Array<any>) => dispatch(Room.Actions.setConferenceData(pin, conferenceMembers)),
    };
};

const Main = connect(
    mapStateToProps,
    mapDispatchToProps
)(MainComponent);
