
const production: boolean = process.env.NODE_ENV === 'production';
const version: string = production && process.env.VERSION ? process.env.VERSION : "SNAPSHOT";

export const DEV_ENDPOINT: string = "wss://kms1.telavox.se:8443/groupcall";

interface AppConfig {
    production: boolean;
    version: string;
}

const appConfig: Readonly<AppConfig> = {
    production,
    version
};

if (!production) {
    console.group('Application config'); // eslint-disable-line no-console
    for (const o in appConfig) {
        // @ts-ignore: Element implicitly has an 'any' type
        console.log(`${o} => ${appConfig[o]}`); // eslint-disable-line no-console
    }
    console.groupEnd(); // eslint-disable-line no-console
}

export default appConfig;
