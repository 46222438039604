import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { Main } from "app/component/main";
import ErrorBoundary from 'app/component/error-boundary';
import { store } from "state/store";
import AirbrakeClientParams from 'app/airbrake/airbrake-client-params';
import { getBundleFilename } from 'app/utils/app-utils';

import "css/style.less";

AirbrakeClientParams.setBundleFilename(getBundleFilename());

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <Main />
        </Provider>
    </ErrorBoundary>,
    document.querySelector("#app")
);

if ((module as any).hot) {
    (module as any).hot.accept();
}
