interface CookieData {
    name?: string;
    ai?: string;
    beta?: string;
    useTvxBeta?: string;
}

const COOKIE_NAME = 'fConf=';
const NAME = 'name:';
const AI = 'ai:';
const BETA = 'beta:';
const USE_TVX_BETA = 'useTvxBeta:';

/**
 * Sets cookie values based on previous set cookie data and the URL
 * params that was provided to update correctly.
 */
export const setCookieData = (urlParams: any) => {
    // TODO WEB-5208: Check if we can accept cookies?
    const previousCookieValue = readCookie();

    const cookieData: CookieData = {
        name: urlParams.userName || previousCookieValue.name,
        ai: urlParams.accountId || previousCookieValue.ai,
        beta: urlParams.beta || previousCookieValue.beta,
        useTvxBeta: urlParams.useTvxBeta || previousCookieValue.useTvxBeta,
    };

    setCookie(cookieData);
};

/**
 * Sets a cookie by taking a CookieData variable and base64 encodes it
 */
const setCookie = (cookieData: CookieData) => {
    const cookieName = `${COOKIE_NAME}`;
    let data = '';
    // Names will sometimes contain characters that cannot be b64-encoded, e.g.
    // a quotation mark '"'. We have to espace such characters.
    const escaped = escape(cookieData.name || '');
    const b64Name = window.btoa(escaped);
    const b64Ai = window.btoa(cookieData.ai || '');
    const b64Beta = window.btoa(cookieData.beta || '');
    const b64UseTvxBeta = window.btoa(cookieData.useTvxBeta || '');
    data += `${NAME}${b64Name}`;
    data += `&${AI}${b64Ai}`;
    data += `&${BETA}${b64Beta}`;
    data += `&${USE_TVX_BETA}${b64UseTvxBeta}`;

    const expireDate = new Date(new Date().setFullYear(new Date().getFullYear() + 3)).toUTCString();
    document.cookie = `${cookieName}${window.btoa(data)};expires=${expireDate}`;
    // TODO If we ever want to sett a cookie from another site - but do we ever?
    // TODO For instance: an iframe in the webapp
    /*
    const origin = window.location.origin;
    if (origin === 'http://localhost:8081' || origin === 'http://127.0.0.1:8081' ) {
        document.cookie = `${cookieName}${window.btoa(data)};`;
    } else {
        document.cookie = `${cookieName}${window.btoa(data)}; SameSite=None; Secure`;
    }
    */
};

/**
 * Decodes the base64 cookie to the readable cookie values
 */
export const readCookie = (): CookieData => {
    const siteCookies = document.cookie.split(/; /g);

    //TODO: See if we can do something like parsing-util#getParamValue
    const cookieRegExp: RegExp = new RegExp(`^${COOKIE_NAME}`);
    const cookie = siteCookies.find(object => cookieRegExp.test(object)) || '';
    const cookieData = window.atob(cookie.substring(COOKIE_NAME.length)).split(/&/g);

    const nameRegExp: RegExp = new RegExp(`^${NAME}`);
    const nameValue = cookieData.find(object => nameRegExp.test(object));
    // After making the b64-decoding, we have to unescape special characters
    // such as the quotation mark '"'.
    const name = nameValue && unescape(window.atob(nameValue.substr(NAME.length)));

    const aiRegExp: RegExp = new RegExp(`^${AI}`);
    const aiValue = cookieData.find(object => aiRegExp.test(object));
    const ai = aiValue && window.atob(aiValue.substr(AI.length));

    const betaRegExp: RegExp = new RegExp(`^${BETA}`);
    const betaValue = cookieData.find(object => betaRegExp.test(object));
    const beta = betaValue && window.atob(betaValue.substr(BETA.length));

    const useTvxBetaRegExp: RegExp = new RegExp(`^${USE_TVX_BETA}`);
    const useTvxBetaValue = cookieData.find(object => useTvxBetaRegExp.test(object));
    const useTvxBeta = useTvxBetaValue && window.atob(useTvxBetaValue.substr(USE_TVX_BETA.length));

    const cookieObject: CookieData = {
        name,
        ai,
        beta,
        useTvxBeta,
    };
    return cookieObject;
};

export const getCookieUsername = ():string | undefined => {
    const cookieData: CookieData = readCookie();
    return cookieData.name || undefined;
};

export const getCookieAccountId = ():string | undefined => {
    const cookieData: CookieData = readCookie();
    return cookieData.ai || undefined;
};

export const getCookieBeta = ():string | undefined => {
    const cookieData: CookieData = readCookie();
    return cookieData.beta || undefined;
};

export const getCookieUseTvxBeta = ():string | undefined => {
    const cookieData: CookieData = readCookie();
    return cookieData.useTvxBeta || undefined;
};
