import * as React from 'react';

import Airbrake from 'app/airbrake/airbrake-client';

interface Props {
    children: React.ReactNode
}

interface State {
    hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        // Display fallback UI
        this.setState({ hasError: true });
        // Send error to Airbrake
        Airbrake.notify({
            error: error,
            params: {info: info.componentStack}
        });
    }

    render() {

        /*
        if (this.state.hasError) {
            // TODO: We should look into the different kind of errors to determine what to render here
        }
        */

        return this.props.children;
    }
}

export default ErrorBoundary;
