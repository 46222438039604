import * as AirbrakeClient from 'airbrake-js';
import AirbrakeClientParams from './airbrake-client-params';
import appConfig from 'app/app-config';

const Airbrake = new AirbrakeClient({
    projectId: 243413,
    projectKey: 'd5c4b0ba846a1c80b12d5836332e3ed7'
    // Use this pattern to blacklist stuff
    // keysBlacklist: [
    //     'password', // exact match
    //     /secret/ // regexp match
    // ]
});

const filterNoticeOnDev = (notice) => {
    // Don't send reports in dev environment
    if (!appConfig.production) {
        console.group('Crash report triggered (but not sent because we are in dev environment)');
        console.info(notice);
        console.groupEnd();

        return null;
    }
    return notice;
};

const setSourceMaps = (notice = {}) => {
    const bundleFilename = AirbrakeClientParams.getBundleFilename();
    notice.context = notice.context || {};

    if (bundleFilename) {
        // We need to set this to Airbrake can map our bundle with the sourceMaps
        // when we use private sourcemaps
        notice.context.sourceMaps = {
            [`**/${bundleFilename}`]: `${bundleFilename}.map`
        };
    }

    return notice;
};

const setVersion = (notice = {}) => {
    notice.context = notice.context || {};
    notice.context.version = appConfig.version;

    return notice;
};

const filterRemoteAddress = (notice = {}) => {
    notice.context = notice.context || {};
    notice.context.remoteAddr = false;
    return notice;
};

Airbrake.addFilter(setSourceMaps);
Airbrake.addFilter(setVersion);
Airbrake.addFilter(filterRemoteAddress);
Airbrake.addFilter(filterNoticeOnDev);

export default Airbrake;
