import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from 'redux-thunk';

import { RootState } from 'state/store';
import * as Client from "state/client";
require('img/account-circle.svg');

export {VideoSession};

const PersonIcon: React.SFC<{}> = () => {
    return (
        <svg width='90px' height='90px' viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="ic_telavox_unknown-contact" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <circle id="Oval" stroke='#727272' fillRule="nonzero" cx="12" cy="12" r="11.5"></circle>
                <g id="baseline-person-24px-(3)-copy" transform="translate(3.000000, 3.000000)">
                    <polygon id="Shape" points="0 0 18 0 18 18 0 18"></polygon>
                    <path d="M9,9 C10.6575,9 12,7.6575 12,6 C12,4.3425 10.6575,3 9,3 C7.3425,3 6,4.3425 6,6 C6,7.6575 7.3425,9 9,9 Z M9,10.5 C6.9975,10.5 3,11.505 3,13.5 L3,15 L15,15 L15,13.5 C15,11.505 11.0025,10.5 9,10.5 Z" id="Shape" fill='#727272' fillRule="nonzero"></path>
                </g>
            </g>
        </svg>
    );
};

const AudioMuteIcon: React.SFC<{}> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
            <path fill='white' d="M19 11h-1.7c0 .74-.16 1.43-.43 2.05l1.23 1.23c.56-.98.9-2.09.9-3.28zm-4.02.17c0-.06.02-.11.02-.17V5c0-1.66-1.34-3-3-3S9 3.34 9 5v.18l5.98 5.99zM4.27 3L3 4.27l6.01 6.01V11c0 1.66 1.33 3 2.99 3 .22 0 .44-.03.65-.08l1.66 1.66c-.71.33-1.5.52-2.31.52-2.76 0-5.3-2.1-5.3-5.1H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c.91-.13 1.77-.45 2.54-.9L19.73 21 21 19.73 4.27 3z" />
        </svg>

    );
};

const VideoMuteIcon: React.SFC<{}> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
            <path fill='none' d="M0 0h24v24H0zm0 0h24v24H0z" />
            <path fill='white' d="M21 6.5l-4 4V7c0-.55-.45-1-1-1H9.82L21 17.18V6.5zM3.27 2L2 3.27 4.73 6H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.21 0 .39-.08.54-.18L19.73 21 21 19.73 3.27 2z"/>
        </svg>

    );
};

const MaximizeFullscreenIcon: React.SFC<{}> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
            <path fill="white" d="M5,5H10V7H7V10H5V5M14,5H19V10H17V7H14V5M17,14H19V19H14V17H17V14M10,17V19H5V14H7V17H10Z"/>
        </svg>

    );
};

const MinimizeFullscreenIcon: React.SFC<{}> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 24 24">
            <path fill='white' d="M14,14H19V16H16V19H14V14M5,14H10V19H8V16H5V14M8,5H10V10H5V8H8V5M19,8V10H14V5H16V8H19Z" />
        </svg>

    );
};


interface FromStateProps {
    remotePeer: Client.RemotePeer;
    alpha: boolean;
}

interface InputProps {
    name:               string;
    fullscreen:         string | undefined;
    position:           number;
    noOfParticipants:   number;
    onToggleFullscreen: (participant: string) => void;
}

interface FromDispatchProps {
    setupReceiverPeer:    (name: string, video: HTMLVideoElement) => void;
    teardownReceiverPeer: (name: string) => void;
}

type Props = InputProps & FromStateProps & FromDispatchProps;

interface ComponentState {
    audioMuted: boolean;
    videoMuted: boolean;
}

interface StatusBarInputProps {
    name:       string;
    audioMuted: boolean;
    videoMuted: boolean;
    alpha?: boolean;
}

interface MaximizeMinimizeInputProps {
    fullscreen: string | undefined;
    name: string;
    toggleFullscreen: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const StatusBar: React.SFC<StatusBarInputProps> = (props) => {

    const noMic = props.audioMuted ?
        <div className='remoteNoMic'><AudioMuteIcon /></div>
        : null;
    const noCam = props.videoMuted ?
        <div className='remoteNoCam'><VideoMuteIcon /></div>
        : null;
    return (
        <div className='status-bar'>
            <div>{props.name}</div>
            {noMic}
            {noCam}
        </div>
    );
}

const MaximizeMinimizeFullscreen: React.SFC<MaximizeMinimizeInputProps> = (props) => {
    return (
        <div 
            className='expand-button-wrapper'
            onClick={props.toggleFullscreen}
        >
            <div className='expand-button'>
                {props.fullscreen === props.name ? <MinimizeFullscreenIcon/> : <MaximizeFullscreenIcon/>}
            </div>
        </div>
    );
};

class VideoSessionComponent extends React.PureComponent<Props, ComponentState> {

    private videoRef?: HTMLVideoElement;

    public componentDidMount() {
        this.props.setupReceiverPeer(this.props.name, this.videoRef!);
    }

    public componentWillUnmount() {
        this.props.teardownReceiverPeer(this.props.name);
    }

    private renderNoRemoteVideo = (videoMuted: boolean) => {
        const style: React.CSSProperties = !videoMuted ? { "display" : "none" } : {};
        return (
            <div className='anonymous-remote' style={style}>
                <div className='error-text' />
                <div className='person-icon'><PersonIcon /></div>
                <div className='error-text'>{'No video received'}</div>
            </div>
        );
    };

    private renderRemoteVideo = (videoMuted: boolean) => {
        const style: React.CSSProperties = videoMuted ? { "display" : "none" } : {};
        return (
            <video
                key={`video-${this.props.name}`}
                ref={(r: HTMLVideoElement) => this.videoRef = r}
                autoPlay={true}
                className='remote'
                onClick={this.toggleFullscreen}
                style={style}
                poster={'img/account-circle.svg'}
            />
        );
    };

    public render() {
        const { name, fullscreen, remotePeer, position, noOfParticipants } = this.props;
        const audioMuted = remotePeer ? remotePeer.audioMuted : false;
        const videoMuted = remotePeer ? remotePeer.videoMuted : false;
        const hidden: boolean = (!!fullscreen && fullscreen !== name);
        const style: React.CSSProperties = hidden ? { "display": "none" } : {};

        const cssClass = 'grid-box' + (noOfParticipants > 1 ? ' pos-' + position : '');

        // Slice name if there is a UUID in it. Looks like `${UUID}-${name}`
        const isGoodName = name.length > 37 && name[36] === '-';
        const displayName = isGoodName ? name.slice(37) : name;

        return (
            <div className={cssClass} style={style}>
                <div className='video-box'>
                    {this.renderNoRemoteVideo(videoMuted)}
                    {this.renderRemoteVideo(videoMuted)}
                    <StatusBar
                      key={`statusbar-${name}`}
                      name={displayName}
                      audioMuted={audioMuted}
                      videoMuted={videoMuted}
                      alpha={this.props.alpha}
                    />
                    <MaximizeMinimizeFullscreen
                        name={name}
                        fullscreen={fullscreen}
                        toggleFullscreen={this.toggleFullscreen}
                    />
                </div>
            </div>
        );
    }

    private toggleFullscreen = () => this.props.onToggleFullscreen(this.props.name);
}

const mapStateToProps = (state: RootState, inputProps: InputProps): FromStateProps => {
    const { remote: { peers }, app: { alpha } } = state;
    const { name } = inputProps;

    const remotePeer = peers.get(name);
    return { remotePeer, alpha };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, {}, any>): FromDispatchProps => {
    return {
        setupReceiverPeer: (name: string, video: HTMLVideoElement) =>
            dispatch(Client.Actions.setupReceiverPeer(name, video)),

        teardownReceiverPeer: (name) =>
            dispatch(Client.Actions.teardownPeer(name))
    };
};

const VideoSession = connect(
    mapStateToProps,
    mapDispatchToProps
)(VideoSessionComponent);
